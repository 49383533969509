@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/keyframes";

.TileSlider {
	display: flex;
	flex-direction: row;
	position: relative;
}

.TileSlider-items {
	width: 100%;
}

.TileSlider-navigate {
	@include hide-text;
	height: 60px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	z-index: 10;
	opacity: 0;
	transition: all $default-transition;

	&:disabled {
		display: none;
	}

	&:focus {
		outline: none;
	}

	&--prev {
		left: -10px;

		@include breakpoint-up(md) {
			left: -55px;
		}

		@include breakpoint-up(lg) {
			left: -85px;
		}

		&::after {
			.BrandBar &,
			.GenreBar & {
				@include cssArrow(left, 2px solid $body-font-color, 12px, true);
				content: "";
				transition: $default-transition;

				@include breakpoint-up(sm) {
					@include cssArrow(left, 3px solid $body-font-color, 20px, true);
				}

				@include breakpoint-up(md) {
					@include cssArrow(left, 3px solid $body-font-color, 24px, true);
				}

				@include breakpoint-up(lg) {
					@include cssArrow(left, 4px solid $body-font-color, 26px, true);
				}
			}

			@include cssArrow(left, 4px solid $body-font-color, 18px, true);
			content: '';
			text-shadow: $text-drop-shadow;
			transition: $default-transition;

			@include breakpoint-up(sm) {
				@include cssArrow(left, 3px solid $body-font-color, 24px, true);
			}

			@include breakpoint-up(md) {
				@include cssArrow(left, 3px solid $body-font-color, 28px, true);
			}

			@include breakpoint-up(lg) {
				@include cssArrow(left, 4px solid $body-font-color, 35px, true);
			}
		}
	}

	&--next {
		right: -10px;

		@include breakpoint-up(md) {
			right: -55px;
		}

		@include breakpoint-up(lg) {
			right: -85px;
		}

		&:not(:disabled)::after {
			.BrandBar &,
			.GenreBar & {
				@include cssArrow(right, 2px solid $body-font-color, 12px, true);
				content: "";
				transition: $default-transition;

				@include breakpoint-up(sm) {
					@include cssArrow(right, 3px solid $body-font-color, 20px, true);
				}

				@include breakpoint-up(md) {
					@include cssArrow(right, 3px solid $body-font-color, 24px, true);
				}

				@include breakpoint-up(lg) {
					@include cssArrow(right, 4px solid $body-font-color, 26px, true);
				}
			}

			@include cssArrow(right, 2px solid $body-font-color, 18px, true);
			content: '';
			transition: $default-transition;

			@include breakpoint-up(sm) {
				@include cssArrow(right, 3px solid $body-font-color, 24px, true);
			}

			@include breakpoint-up(md) {
				@include cssArrow(right, 3px solid $body-font-color, 28px, true);
			}

			@include breakpoint-up(lg) {
				@include cssArrow(right, 4px solid $body-font-color, 35px, true);
			}
		}
	}

	.ShowTileArea:hover &,
	.VideoTileArea:hover &,
	.BrandBar:hover &,
	.GenreBar:hover & {
		opacity: 1;

		&:hover {
			transform: translateY(-50%) scale(1.4);
		}
	}

	.VideoTileArea & {
		// Vertically center the arrows on the tile image
		top: calc((100vw - 2 * var(--body-margin)) / 4 * 0.7 / 2);

		@include breakpoint-up(md) {
			top: calc((100vw - 2 * var(--body-margin)) / 4 * 0.55 / 2);
		}
	}
}

.TileSlide {
	transition: all $default-transition;

	&:not(.TileSlide--active) {
		opacity: 0.5;
		pointer-events: none;
	}

	// Pop-in animation for tile slides.
	> *:not(.BrandBar-link) {
		animation: tile-loading-animation 0.6s ease-in-out forwards;
		display: block;
		opacity: 0;
	}

	@for $i from 0 to 9 {
		&:nth-child(#{$i + 1}) > * {
			animation-delay: #{$i * 0.1}s;
		}
	}

	&.link-out {
		display: flex !important;
	}
}

.TilePosition {
	transition: all 0.85s ease-in;
	position: absolute;
	opacity: 0 !important;
	pointer-events: none;

	&.TileSlide--active {
		opacity: 1 !important;
		pointer-events: visible;
		position: relative;
	}
}

.ShowTileArea {
	// Directly targeting drawer slider
	> .TileSlider > .TileSlider-items > .TileSlide {
		align-content: end;
		display: grid;
	}
}
