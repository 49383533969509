.NotFound {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 400px;
	justify-content: center;
	padding: 0 10vw;
	text-align: center;
	width: 100%;
}

.NotFound-title {
	font-size: 40px;
}

.NotFound-message {
	margin-bottom: 40px;
	font-size: 24px;
}
