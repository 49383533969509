@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.Activate-form {
	.Activate-message {
		font-size: 16px;
		line-height: 24px;
		font-weight: $font-weight-normal;
		letter-spacing: .5px;
		margin-bottom: 24px;

		@include breakpoint-up(sm) {
			margin-bottom: 40px;

		}

		@include breakpoint-up(md) {
			font-size: 18px;
		}

		&--small {
			font-size: 10px;
			letter-spacing: .5px;
			line-height: 16px;
			margin: 50px 0;

			@include breakpoint-up(md) {
				font-size: 12px;
			}

			a:hover {
				text-decoration: underline;
				transition: all $default-transition;
			}
		}
	}

	.Activate-field {
		flex: 1 0 auto;
		margin: 0 25%;
		padding: 0;

		.form-control {
			text-align: center;
			text-transform: uppercase;
		}
	}

	input {
		background-color: $color-grey-10;
		border: 0;
		color: $body-font-color;
		display: block;
		font-family: $body-font-family;
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 24px;
		margin: 10px 0;
		padding: 10px;
		text-align: center;
		width: 100%;

		@include breakpoint-up(md) {
			margin: 12px 0;
			padding: 15px;
		}

		&:focus {
			outline: 1px solid $focus-outline-color;
		}
	}


	.Activate-button {
		align-self: center;
		background-color: $globaltv-red;
		border: 1px solid $globaltv-red;
		font-size: 16px;
		letter-spacing: 0.5px;
		font-weight: $font-weight-medium;
		margin-top: 14px;
		min-width: 75px;
		padding: 12px 26px;
		text-align: center;
		text-transform: uppercase;
		transition: 0.3s;

		&:disabled {
			opacity: .5;
		}

		&:focus {
			outline: 1px solid $focus-outline-color;
		}

		&:not(:disabled):hover {
			background-color: transparent;
			transition: 0.3s;
		}

		@include breakpoint-up(sm) {
			margin-top: 20px;
			margin-top: 16px;
			font-weight: $font-weight-medium;

		}

		@include breakpoint-up(md) {
			font-size: 14px;
		}
	}

}

.Activate-error {
	color: $globaltv-red;
	font-size: 13px;
	margin-top: 5px;
}

.Activate-success {
	flex: 0 1 auto;
	font-size: 20px;
}

.Activate-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 200px;

	.Spinner {
		flex: 0 1 auto;
		font-size: 50px;
		height: 60%;
		text-align: center;
	}
}
