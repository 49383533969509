@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/shared-styles";

html {
  scroll-behavior: smooth;
}

.Promo-wrapper {
  background: $body-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-width: 100vw;
  overflow-y: auto;
  padding: 48px var(--body-margin);

  &.freeplay-section {
    min-height: auto;

    .Promo-textBox {
      justify-content: center;
    }
  }
}

.Product-section {
  .button-primary {
    margin-top: 8px;
  }

  .AppPromo-textBox{
    margin-top: 25px
  }

  padding-top: 0px;
}

.AppInfo-wrapper {
  display: flex;
  flex-direction: column;
  padding: 48px var(--body-margin);
  gap: 32px;
  align-self: stretch;
  background: var(--hero-white, #f2f2f2);
  width: 100%;

  @include breakpoint-up(md) {
    flex-direction: row;
  }
}

.AppInfo-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px;
  gap: 8px;
  flex: 1 1 0;
  height: auto;
  background: var(--solid-white, #fff);

  @include breakpoint-up(sm) {
    padding: 32px;
    gap: 24px;
  }

  p {
    color: var(--solid-black, #000);
    font-size: 14px;

    @include breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  .button-primary {
    &:hover {
      color: #ef2601;
    }
  }
}
.buttonWrapper {
  display: grid;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  grid-gap: 16px;
  margin-top: 8px;

  @include breakpoint(mm, md) {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint-up(xm) {
    grid-template-columns: 1fr 1fr;
  }
}

.AppPromo-container {
  display: grid;
  grid-gap: 32px;

  > .AppPromo-ImageWrap {
    display: none;
  }

  @include breakpoint-up(md) {
    > .AppPromo-ImageWrap {
      grid-column: 2;
      grid-row: 1;
      display: block;
    }
  }
}

.AppPromo-brand-logo {
  margin: 0 auto;
  width: 80px;

  @include breakpoint-up(sm) {
    width: 100px;
  }
}

.AppPromo-textBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  align-self: stretch;

  p {
    margin: 0;
  }

  a {
    display: grid;
  }

  .button-primary,
  .button-secondary {
    font-size: 16px;

  }

  @include breakpoint-up(md) {
    font-size: 20px;
    text-align: left;

    > .AppPromo-ImageWrap {
      grid-column: 2;
      grid-row: 1;
      display: none;
    }
  }
}
.AppPromo-title {
  color: var(--hero-white, #f2f2f2);
  white-space: pre-wrap;
  font-size: 20px;
  font-family: Red Hat Text;
  font-weight: 500;

  @include breakpoint-up(sm) {
    font-size: 26px;
  }
  @include breakpoint-up(md) {
    font-size: 28px;
  }
}

.AppPromo-body {
  color: var(--hero-white, #f2f2f2);
  font-size: 16px;
  font-family: Red Hat Text;
  line-height: 24px;

  @include breakpoint-up(sm) {
    font-size: 18px;
  }

  @include breakpoint-up(md) {
    align-items: center;
  }
}

.AppPromo-conditionText {
  color: var(--grey-lt-grey-c-6, #c6c6c6);
  font-size: 11px;
  font-family: Red Hat Text;
  line-height: 24px;

  @include breakpoint-up(sm) {
    font-size: 13px;
  }
}

.AppPromo-ImageWrap {
  display: flex;
  justify-content: center;
  align-self: center;
}

.AppPromo-heroImage {
  object-fit: contain;
  width: 344px;
  height: 202px;

  @include breakpoint-up(mm) {
    width: 650px;
    height: 382.35px;
  }

  @include breakpoint-up(md) {
    width: 545px;
    height: 320px;
  }

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      margin-top: 8px;
      margin-bottom: 8px;
  }

}

.AppPromo-logo {
  width: 139.487px;
  height: 39.532px;
  flex-shrink: 0;
  align-self: center;

  @include breakpoint-up(md) {
    align-self: start;
  }
}

.Faq-wrapper {
  display: flex;
  padding: 48px var(--body-margin);
  flex-direction: column;
  gap: 32px;
  align-items: center;
  align-self: stretch;
  background: var(--hero-white, #f2f2f2);
}

.Brand-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.button-promo {
  font-size: 16px;
  margin-top: 16px;

  @include breakpoint-up(md) {
    margin-top: 0px;
  }
}

.storeLink {
  max-width: 160px;

  @include breakpoint-up(md) {
    height: 50px;
    max-width: 200px;
  }
}