.ErrorBoundary {
	justify-content: center;
	display: flex;
	padding: 40px 0;
}

.ErrorBoundary-text {
	color: #888;
	font-size: 17px;
}
