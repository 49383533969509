@import "src/styles/mixins";
@import "src/styles/variables";

.Footer {
  align-items: center;
  background-color: black;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 35px 20px;

  @include breakpoint-up(sm) {
    margin-top: 55px;
  }

  @include breakpoint-up(md) {
    margin-top: 140px;
    padding: 65px 20px;
  }
}

.Footer-socialLinks {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @include breakpoint-up(md) {
    margin-bottom: 65px;
  }

  .SocialIcon {
    height: 25px;
    margin: 5px;
    width: 25px;

    &--twitter {
      display: flex;

      img {
        margin: 5px;
      }
    }

    @include breakpoint-up(md) {
      height: 28px;
      width: 28px;
    }
  }
}

.Footer-nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  // margin: 0 -32px 30px;
  padding: 0;

  @include breakpoint-up(sm) {
    flex-direction: row;
  }

  &-item {
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 48px;
    margin: 0 14px;
    align-self: center;

    @include breakpoint-up(md) {
      font-size: 14px;
      margin: 0 32px;
    }

    &-link:hover {
      text-decoration: underline;
    }
  }
}

.Footer-copyrightText {
  color: $color-grey-50;
  font-weight: $font-weight-normal;
  font-size: 12px;
  margin-bottom: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;

  @include breakpoint-up(sm) {
    display: unset;
  }
}

.Footer-corusLogo {
  @include hide-text;

  background-image: url("../../../assets/icons/corus-logo.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 40px;
  width: 142px;

}
