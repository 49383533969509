@import "src/styles/variables";
@import "src/styles/mixins";

.Close-cross {
  width: 32px !important;
  height: 32px;
  border-radius: 50%;
  display: flex;
  background: rgba(183, 183, 183, 0.6);
  justify-content: center;
  align-items: center;
  left: 63% !important;
  font-size: 40px !important;
}
.MetaData-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60vw;

  @include breakpoint-up(md) {
    width: 70vw;
  }

  @include breakpoint-up(lg) {
    width: 80vw;
  }

  @include breakpoint-up(xl) {
    width: 85vw;
  }
}
.MetaData-title {
  font-family: $title-font;
  font-size: 26px;
  font-weight: $font-weight-normal;
  margin-bottom: 8px;
  max-width: 40%;
  line-height: 1.2;

  @include breakpoint-up(md) {
    font-size: 32px;
  }
}

.MetaData-label{
  width: 75%;
  font-weight: 500;
	letter-spacing: 0.75px;

  @include breakpoint-up(md) {
    width: 65%;
  }
}

.MetaData-description {
  width: 75%;
  white-space: normal;
  color: #f2f2f2;
  font-size: 11px;
  letter-spacing: 0.3px;
  line-height: 1.35;
  text-shadow: $text-drop-shadow;
  margin-top: 8px;
  margin-bottom: 24px;

  @include breakpoint-up(md) {
    width: 65%;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.45;
  }
}
.Metadata-cast {
  display: flex;
  color: #b7b7b7;
  font-size: 14px;
  margin-bottom: 4px;
}
.Metadata-rating {
  display: flex;
  color: #b7b7b7;
  font-size: 14px;
  margin-top: 4px;
}

.Metadata-Subtitle {
  display: flex;
  margin: 4px 0;
  font-size: 18px;
  align-items: center;
}
.Close-show-metadata {
  color: white;
  font-size: 40px;
  position: absolute;
  transition: $default-transition;
  width: 50px;

  &:hover {
    transform: scale(1.15);
  }

  @include breakpoint-up(md) {
    font-size: 50px;
  }

  .Close {
    font-weight: $font-weight-light;
    z-index: 9999;
  }
}

.metadata-watch-buttons {
  display: flex;
  margin-top: 24px;
  a {
    margin-right: 20px;
  }
  div.button-primary {
    display: inline-block;
    margin-right: 20px;
  }
}

.artwork > img{
  width: 200px;
  height: 100%;
}

@media screen and (min-width: 1800px) {
  .MetaData-description, .MetaData-label {
    width: 55%;
  }
  .Close-cross {
    left: 55% !important;
  }
}