@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.Activate {

	flex: 1 1 auto;
	padding: 0 var(--body-margin);
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;

	@include breakpoint-up(sm) {
	margin-block: unset;
	display: flex;
    flex-direction: column;
    justify-content: center;
	}

	&-title {
		animation: $default-fade-in;
		display: block;
		font-family: $title-font;
		font-size: 20px;
		font-weight: $font-weight-medium;
		line-height: 32px;
		margin-bottom: 30px;
		text-align: center;

	
		@include breakpoint-up(md) {
			font-size: 32px;
			margin-bottom: 40px;
		}
	}

	&-wrapper {
		margin: auto;
		text-align: center;
		width: 90%;

		@include breakpoint-up(sm) {
			width: 70%;
		}

		@include breakpoint-up(md) {
			width: 960px;
		}
	}

}
