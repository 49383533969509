@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';
@import 'src/styles/functions';

.ShowTileArea {
	padding: 14px 0;

	@include breakpoint-up(md) {
		padding: 28px 0;
	}

	> .TileSlider,
	> .GridTileArea {
		margin: 0 var(--body-margin);
		z-index: 1; // For stacking over the show section, where applicable
	}

	> .ShowTile-Loader {
		margin: 0 var(--body-margin);
	}

}

.ShowTileArea-title {
	display: inline-block;
	padding-left: var(--body-margin);
	padding-right: 20px;

	@include breakpoint-up(md) {
		padding-right: 30px;
	}
}

.ShowTile--selected::before {
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-top: 10px solid $body-font-color;
	content: '';
	left: 50%;
	position: absolute;
	top: calc(100% - 1px);
	transform: translateX(-50%);
}

.ShowTile--selected{
	.ShowTile-image {
		outline: 4px solid $body-font-color;
		outline-offset: -4px;
	}
}
