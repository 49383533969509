.signup-modal {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    touch-action: none;
    width: 100%;
    z-index: 1000000;
    .logo {
        max-width: 90%;
    }
}
.signup {
    animation: zoom-in 0.4s ease forwards;
    background-color: #fefefe;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #101010;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 400px;
    overflow: hidden;
    padding: 2vh 4vw 2vh 4vw;
    text-align: start;
    width: 80%;
    position: relative;
}

.signup-close-btn {
    animation: zoom-out 0.2s ease-out forwards;
}

.signup-text {
    flex: 0 0 auto;
    margin: 50px 0 5px 0;
    overflow: auto;
    &--bolder {
        font-weight: 600;
        padding-bottom: 15px;
        font-size: 24px;
    }
    p {
        margin: 0;
    }
}
.signup-close {
    font-size: 2.1rem;
    position: absolute;
    right: 15px;
    top: 15px;
    color: rgba(0, 0, 0, 0.8);
    border: 0
}

.signup-header-top,
.signup-header-bottom {
    display: flex;
    flex-flow: row-reverse;
    font-size: 14px;
    justify-content: space-between;
    text-transform: uppercase;
    width: 30px
}

.input-group {
    margin-bottom: 1.5vh;
}
.input-group:nth-last-child(-n + 2) {
    margin-bottom: 50px;
}
.input-group > label,
.input-group > input {
    text-align: left;
    width: 90%;

}
.input-group > label {
    color: #676767;
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.input-group > input {
    background: #f2f2f5;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    color: #676767;
    font-size: 1rem;
    height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: all 0.3s;
    
}

.input-group > #pincode{
   width: 30% ;
}
.input-group > input:focus {
    background: #fff;
    border-color: #c4c4c4;
}
.input-group > input::placeholder {
    color: #c4c4c4;
    font-size: 0.8rem;
}

.button {
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    max-width: 420px;
    outline: 0;
    padding: 10px 0;
    transition: background 0.3s;
}
.button.primary {
    background: #101010;
    color: #fff;
    margin-top: 4vh;
}
.button.primary:hover {
    background: #ea6c0b;
}
.secondary {
    outline: 0;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline;
}
button.secondary:hover {
    background: #a8c787;
}
.signup-body {
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    overflow: hidden;
    margin-top: 30px;

}

.signup-footer {
    font-size: 16px;
    align-self: center;

    p {
        margin-bottom: 0;
        margin-top: 0.4rem;
    }

    p:first-child,
    span {
        margin-top: 1rem;
    }

    a {
        color: #101010;

        &:hover {
            color: red;
            text-decoration: underline;
            // transition: all $default-transition;
        }
    }
    span {
        display: inline-block;
        margin-top: 2rem;
        a {
            text-decoration: underline;
        }
    }
}
