@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/keyframes';

.HelpModal-wrapper {
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	touch-action: none;
	width: 100%;
	z-index: 10000011;
}

.HelpModal {
	animation: zoom-in 0.4s ease forwards;
	background-color: black;
	border-radius: 5px;
	color: $body-font-color;
	display: flex;
	flex-direction: column;
	height: 480px;
	max-width: 720px;
	overflow-y: auto;
	padding: 50px;
	text-align: center;
	width: 90%;

	@include breakpoint-up(sm) {
		height: 540px;
		padding: 80px 60px;
		width: 80%;
	}

	@include breakpoint-up(md) {
		height: auto;
		overflow-y: hidden;
	}
}

.HelpModal-body {
	flex: 1 0 auto;

	.Spinner {
		font-size: 60px;
		height: 40%;
	}
}

.HelpModal-form {
	width: 100%;
}

.HelpModal-row {
	display: flex;
	flex-direction: column;

	@include breakpoint-up(sm) {
		flex-direction: row;
	}
}

.HelpModal-field {
	flex: 1 0 auto;
	padding: 0;

	@include breakpoint-up(sm) {
		padding: 0 8px;
	}

	@include breakpoint-up(md) {
		padding: 0 12px;
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	input,
	select,
	textarea {
		background-color: $color-grey-10;
		border: 0;
		color: $body-font-color;
		display: block;
		font-family: $body-font-family;
		font-size: 10px;
		letter-spacing: .4px;
		margin: 10px 0;
		padding: 10px;
		width: 100%;

		@include breakpoint-up(sm) {
			font-size: 13px;
		}

		@include breakpoint-up(md) {
			margin: 12px 0;
			padding: 15px;
		}

		&:focus {
			outline: 1px solid $focus-outline-color;
		}
	}

	select {
		-webkit-appearance: none;
		background: $color-grey-10 url('../../../assets/icons/arrow-down.svg') no-repeat right;
		background-position: right 10px center;
		background-size: 30px 30px;
	}

	textarea {
		height: 5rem;
		resize: none;
	}

}

.invalid-feedback {
	color: $globaltv-red;
	display: none;
	font-size: 13px;
	margin-top: -10px;
}

.was-validated {
	.form-control {
		&:invalid {
			border: 1px solid $globaltv-red;

			~ .invalid-feedback {
				display: block;
			}
		}
	}
}

.HelpModal-close {
	color: $body-font-color;
	font-size: 40px;
	position: absolute;
	right: 10px;
	top: 2px;
}

.HelpModal-title,
.HelpModal-description {
	font-weight: $font-weight-light;
	margin-top: 0;
}

.HelpModal-title {
	font-family: $title-font;
	font-size: 16px;

	@include breakpoint-up(sm) {
		font-size: 20px;
	}

	@include breakpoint-up(md) {
		font-size: 22px;
	}
}

.HelpModal-description {
	font-size: 12px;
	letter-spacing: .5px;
	margin-bottom: 8px;

	@include breakpoint-up(sm) {
		font-size: 14px;
		margin-bottom: 16px;
	}
}

.help-modal-text {
	flex: 0 0 auto;
	margin: 1.6rem 0 0 0;
	overflow: auto;
}

.noscroll {
	overflow: hidden;
	position: fixed;
	width: 100vw;
}

.HelpModal-button {
	align-self: center;
	background-color: $globaltv-red;
	border: 1px solid $globaltv-red;
	font-size: 10px;
	letter-spacing: 0.5px;
	margin-top: 16px;
	min-width: 75px;
	padding: 12px 26px;
	text-align: center;
	text-transform: uppercase;
	transition: 0.3s;

	&:focus {
		outline: 1px solid $focus-outline-color;
	}

	&:hover {
		background-color: transparent;
		transition: 0.3s;
	}

	@include breakpoint-up(sm) {
		font-size: 12px;
		margin-top: 20px;
	}

	@include breakpoint-up(sm) {
		font-size: 14px;
	}

}
