@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';


.ShowTile {
	cursor: pointer;
	position: relative;
	transition: transform $default-transition;

	&:hover {
		transform: scale(1.05);
		z-index: 1;
	}
}

.ShowTile-image {
	display: block;
	width: 100%;
}

.ShowTile-text {
	font-size: 20px ;
	font-family: $title-font-two;
}

.ShowTile-caption {
	border-left: 5px solid $globaltv-red;
	color: $body-font-faded-color;
	font-weight: $font-weight-medium;
	font-size: 9px;
	height: 12px;
	line-height: 12px;
	margin-bottom: 7px;
	padding-left: 5px;

	@include breakpoint-up(md) {
		font-size: 12px;
		white-space: nowrap;
	}
}

.ShowTile-loader {
	&.ShowTile {
		animation: pulse 1.5s ease-in infinite;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $loader-bg-color;
	}
}

.view-more {
	align-content: center;
	border: 1px solid $body-font-color;
	display: grid !important;
	justify-content: center;
	width: 100%;
	background-color: #282828;
}

.with-marketing-decor {
	.view-more {
		margin-top: 18px;
	}
}

.chevron-right {
	&::before {
		border-style: solid;
		border-width: 3px 3px 0 0;
		content: '';
		display: block;
		height: 16px;
		right: 3px;
		position: relative;
		top: 16px;
		transform: rotate(45deg);
		width: 16px;
	}
	display: inline-block;
}

.icon-box{
	animation: zoom-in 0.3s ease-in;
	background-color: #5E5E5E;
	cursor: pointer;
	border-radius: 24px;
	margin-bottom: 24px;
	width: 48px;
	height: 48px;
	opacity:0.9;
	text-align:center;
	overflow: hidden;
	justify-self: center;
}