@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/shared-styles";

.VideoPlayer {
	height: 100%;

	.videoplayerAuthMessage {
		min-height: 100vh;
	}

	.VideoPlayer-authMessage-title {
		font-family: $title-font;
		font-size: 20px;
		font-weight: $font-weight-light;
		letter-spacing: 1.2px;
		margin: 0 var(--body-margin);
		padding: 10px 0 6px;

		@include breakpoint-up(sm) {
			font-size: 30px;
		}
	}

	.VideoPlayer-authMessage-cta {
		background-color: $globaltv-red;
		border: 2px solid $globaltv-red;
		cursor: pointer;
		font-family: $body-font-family;
		font-size: 16px;
		letter-spacing: 0.5px;
		margin-top: 15px;
		padding: 11px 20px;
		text-transform: uppercase;
		transition: $default-transition;

		&:hover {
			background-color: transparent;
		}

		&::before {
			display: none;
		}

		@include breakpoint-up(sm) {
			margin-top: 30px;
			padding: 18px 35px;
		}

		@include breakpoint-up(md) {
			margin-top: 50px;
		}

		&-label {
			padding-left: 0;
		}
	}

	.VideoPlayer-playerContainer {
		height: 100%;
		position: relative;

		.jw-title {
			// Ensure title goes overtop of overlay.
			background: none;
			z-index: 1001;
		}

		.videoErrorMessage-copy {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.videoplayerAuthMessage-copy {
			align-items: center;
			background: rgba(0, 0, 0, 0.7);
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			min-height: 100%;
			min-width: 100%;
			width: 100%;

			.videoplayerAuthMessage-copy-title {
				@extend .VideoPlayer-authMessage-title;
			}

			.videoplayerAuthMessage-copy-message {
				font-size: 16px;

				@include breakpoint-up(sm) {
					font-size: 20px;
				}
			}

			.videoplayerAuthMessage-cta {
				@extend .VideoPlayer-authMessage-cta;
			}
		}

		.adBlockerMessage {
			width: 100%;

			.videoErrorMessage-copy {
				.icon-face {
					color: $globaltv-red;
				}

				.icon-ctrl {
					display: none;
				}

				&-title {
					font-family: $body-font-family;
					font-weight: $font-weight-semi-bold;
				}

				.videoErrorMessage-cta-wrapper {
					margin-top: 20px;

					.videoErrorMessage-cta {
						background: $globaltv-red;
						font-size: 16px;
						font-weight: $font-weight-normal;
						padding: 16px 20px;
					}
				}

				.videoErrorMessage-copy-message {
					margin: 0 auto;
					text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);

					@include breakpoint-up(lg) {
						width: 50%;
					}
				}
			}
		}
	}

	.VideoPlayer-authMessage {
		align-items: center;
		background: url("../../../../assets/placeholders/livetv/LiveTV_bg.jpg") no-repeat;
		background-color: #333333;
		background-size: cover;
		box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
		display: flex;
		font-family: $body-font-family;
		justify-content: center;
		min-height: 800px;

		.VideoPlayer-authMessage-content {
			align-items: center;
			display: flex;
			font-family: $title-font;
			flex-direction: column;
			justify-content: center;
		}
	}

	.VideoPlayer-inner--loading {
		padding-top: 56.25%;

		.Spinner {
			font-size: 36px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.state--error .VideoPlayer-inner--loading,
	.jw-error + .VideoPlayer-inner--loading,
	.jwplayer:not(.jw-state-setup) + .VideoPlayer-inner--loading {
		// A little fragile. Spinner must be adjacent to player
		// for loading state to hide properly.
		display: none;
	}

	.jwplayer .welcomeBackOverlay {
		h3 {
			font-size: 30px;
			line-height: 1;
			margin-bottom: 24px;
		}

		p {
			font-size: 20px;
			margin-bottom: 50px;
		}

		.button {
			@extend %button-primary;
			margin: 0 24px;

			@include breakpoint-up(sm) {
				font-size: 16px;
				padding: 18px 30px;
			}
		}
	}
}

.jw-ads-view > div {
	height: 100vh !important;
}
.jw-skin-corus .jw-nextup-container {
	max-width: 450px;
}

.jwplayer .countdown-overlay .countdown-cancel {
	outline: none;
}

// Force show Disable Now button on smallest breakpoint
@media screen and (max-width: 561px) {
	.videoErrorMessage-cta-wrapper {
		margin: 0 !important;

		.videoErrorMessage-cta {
			display: block !important;
			font-size: 11px !important;
			margin: 0 auto;
			padding: 12px 8px !important;
			width: 60%;
		}
	}

	.videoErrorMessage-copy {
		padding: 0 3% !important;
	}
}