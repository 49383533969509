.share-container{
	position: relative;
	margin-left: 16px;
	border-radius: 50%;
}

.share-wrapper{
	position: relative;
	width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    background: #282828;
    justify-content: center;
    align-items: center;
	cursor: pointer;

	&:hover {
		border: 1px solid white;
	}
}

.share-overlay {
	align-items: center;
    background-color: white;
    color: black;
    display: flex;
	height: 140px;
	justify-content: center;
	position: absolute;
	flex-direction: column;
    right: -50%;
	touch-action: none;
	width: 160px;
	z-index: 1000;
	padding: 10px 0;
	border-radius: 10px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right:25px;
		transform: translateY(-100%);
		border-top: 13px solid transparent;
		border-right: 13px solid transparent;
		border-left: 13px solid transparent;
		border-bottom: 10px solid #F2F2F2;
		
	}
}
.linkList{
	font-size: 14px ;
	font-weight: 500;
	color: #5E5E5E;
	background: #FFFFFF;

	&:hover {
		color: #000000;
		background: #F2F2F2;
	}
  
	&:active {
		color: #000000; 
		Background: #C6C6C6;
	}
}

.linkIcon{
	width: 24px;
	margin-right: 8px;
}

.linkButton{
	display: flex;
	cursor: pointer;
    align-items: center;
	margin-left: 16px;
    margin-right: 8px;
	width: 140px;
	
}
.share-overlay > li {
	margin : 0;
	padding: 8px 0;
	list-style: none;
	border-top: 1px solid #999999;
    width: 100%;

}
.share-overlay > li:nth-of-type(1){
	border-top: 0;
    width: 100%;
}