.channel__logo-container {
    justify-content: center;
    display: flex;
    flex-direction: column;

    .channel__logo {
        display: flex;
        justify-content: center;
        align-items: center;

        .channel__logo-icon {
            align-self: center;
            margin-top: 10px;
            padding-right: 16px;
        }
        .channel__img {
            width: 78px;
            height: 55px;
            object-fit: contain;
        }
        .channel__img__inactive {
            margin-left: 10;
            width: 78px;
            object-fit: contain;
            opacity: 0.5;
        }
    }
    .sign_in {
        color: #b7b7b7;
        font-size: 12px;
        text-transform: uppercase;

        &.sign_in:hover {
            color: white;
        }
    }
    .channel__logo-inactive{
        cursor:default;
    }
}
