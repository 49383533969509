@import 'src/styles/mixins';
@import 'src/styles/variables';

.GenreBar {
	margin: 0 var(--body-margin);
	text-align: center;
    align-self: center;
}

.GenreBar-title {
	font-size: 18px;
	color: #F2F2F2;
	margin: 0 var(--body-margin);
	font-family: $title-font-two ;
	margin-bottom: 16px;
	letter-spacing: -0.03 px;
}

.GenreBar-link {
    background: #282828;
    color: #B7B7B7;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    text-align: center;

	padding: 16px;
	overflow: hidden;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;

	@include breakpoint-up(xs) {
		font-size: 11px;
		height: 56px;
		}

	@include breakpoint-up(sm) {
		font-size: 13px;
		height: 60px;

	}

	@include breakpoint-up(md) {
		font-size: 13px;
		height: 64px;

	}
	@include breakpoint-up(xl) {
		font-size: 18px;
		height: 72px;

	}

    &:hover {
        background: #000000;
        color: #F2F2F2;

	}

    &.active{
        background: #141414;
        color: #F2F2F2;
    }
}
