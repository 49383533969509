.like{
    width:41px;
    height:41px;
    border-radius: 50%;
    display: flex;
    background: #282828;
    justify-content: center;
    align-items:center;
    cursor: pointer;
}
.like{
    &:hover,&.border-fav{
        background: #000;
        border: 1px solid #ffffff; 

    }

}
