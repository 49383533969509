@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.LiveTV {
	.BrandBar {
		margin-bottom: 60px;
	}
}

.LiveTV-title {
	animation: $default-fade-in;
	display: block;
	font-family: $title-font;
	font-size: 20px;
	font-weight: $font-weight-light;
	margin-bottom: 40px;
	text-align: center;

	@include breakpoint-up(md) {
		font-size: 32px;
	}
}

.LiveTV-player-wrapper {
	margin: 0 var(--body-margin);
	position: relative;
	text-align: right;

	.jw-title-primary.jw-reset-text {
		font-family: $body-font-family;
	}
}

.LiveTV-buttons-overlay {
	height: 22%;
	width: 100%;
	display: inline-block;
	order: -1;
	position: relative;

	// Hides while playback is active.
	.jw-flag-user-inactive.jw-state-playing:not(.jw-flag-media-audio):not(.jw-flag-audio-player):not(.jw-flag-ads-vpaid-controls):not(.jw-flag-casting) ~ & {
		opacity: 0;
		visibility: hidden;
	}

	@include breakpoint-up(sm) {
		background: linear-gradient(rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0.5) 45%, transparent 100%);
		left: 0;
		position: absolute;
		top: 0;
		transition: all $default-transition;
	}

	.Dropdown,
	.Video-overlay-helpButton{
		display: inline-block;
		position: relative;
	}

	.Dropdown {
		margin-bottom: 15px;
		margin-right: 15px;

		&::after {
			border-right-width: 2px;
			border-top-width: 2px;
		}

		&-select {
			background-color: transparent;
			border: 2px solid $body-font-color;
			border-radius: 0;
			outline: none;
			padding: 9px 30px 9px 9px;

			&::after {
				left: -19px;
			}

			@include breakpoint-up(md) {
				font-size: 12px;
			}

			option {
				background: $body-background;
				line-height: 1.5;
			}
		}

		@include breakpoint-up(sm) {
			right: 60px;
			top: 45px;
		}
	}

	.Video-overlay-helpButton {
		right: 0;
		top: 1px;

		@include breakpoint-up(sm) {
			right: 45px;
			top: 49px;
		}

		@include breakpoint-up(md) {
			top: 48px;
		}
	}
}

.VideoPlayer-playerContainer {
	display: flex;
	flex-direction: column;
}
