@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/keyframes";

.ShowSection {
	background-color: #000;
	display: flex;
	flex-direction: column;
	grid-column: 1 / -1;
	padding-bottom: 20px;
	position: relative;
	margin-top: 16px;
	font-weight: $font-weight-normal;


	&.closing {
		animation: fade-out-and-slide-up 0.4s ease-out;
	}

	.Shows & {
		left: 50%;
		margin-left: -50vw;
		position: relative;
		width: 100vw;
	}

	.VideoTileArea {
		padding-bottom: 30px;

		@include breakpoint-up(md) {
			padding-bottom: 60px;
		}
	}

	.Close-button-wrapper {
		background: radial-gradient(ellipse at top right, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%);
		cursor: pointer;
		height: 120px;
		position: absolute;
		right: 0;
		top: 0;
		width: 120px;
		z-index: 10;
	}

	.Close-show-section {
		color: white;
		font-size: 40px;
		position: absolute;
		right: 15px;
		top: 8px;
		transition: $default-transition;
		width: 50px;

		&:hover {
			transform: scale(1.15);
		}

		@include breakpoint-up(md) {
			font-size: 50px;
		}

		.Close {
			font-weight: $font-weight-light;
			z-index: 9999;
		}
	}

	.Empty-data{
		font-size: 14px;
		line-height: 14px;
		color: $body-font-faded-color;
		text-align: center;
		font-family: $title-font-two;
		padding: 50px 0px;
		z-index: 1;
	}
}

.ShowSection-image {
	flex: 0 0 33%;
}

.ShowSection-details {
	animation: fade-in 1s ease-in;
	background-position: center 75%;
	background-repeat: no-repeat;
	background-size: auto 100%;
	display: flex;
	flex-direction: column;
	min-height: 290px;
	min-width: 0px;
	padding: 60px var(--body-margin);
	position: relative;
	z-index: 3;

	@include breakpoint-up(md) {
		background-repeat: no-repeat;
		background-size: cover; //auto
		background-position: center; //unset
		min-height: 340px;
	}

	@include breakpoint-up(lg) {
		min-height: 450px;
	}

	&::before {
		background: linear-gradient(to right, rgba(0,0,0,1) 20%, rgba(20,20,20,0) 100%);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 65%;
		z-index: -1;
	}

	.gradient{
		position: absolute;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;

		& div:nth-child(1){
			height: 600px;
			position: absolute;
			top:0;
			z-index: -2;
			width:100%;
			&::before{
				background: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
				bottom: 0;
				content: '';
				height: 40%;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: -2;
			}
			@include breakpoint-up(md) {
				height: 340px;
			}

			@include breakpoint-up(lg) {
				height: 450px;
			}

		}
		& div:nth-child(2){
			background-color: #000000;
			height: 100%;
			width: 100%;
			top: 600px;
			position: absolute;
			z-index: -2;

			@include breakpoint-up(md) {
				top: 340px;
			}

			@include breakpoint-up(lg) {
				top: 450px;
			}
		}
	}

	.Dropdown-select {
		font-size: 10px;

		@include breakpoint-up(md) {
			min-width: 130px;
		}

		@include breakpoint-up(lg) {
			font-size: 12px;
		}
	}
}

.ShowSection-logo {
	height: auto;
	margin-right: auto;
	width: 85px;

	&-globalnewsott {
		width: 60px;

		@include breakpoint-up(md) {
			width: 70px;
		}

	}

	&-global {
		width: 50px;

		@include breakpoint-up(md) {
			width: 75px;
		}
	}

	&-showcase {
		width: 40px;

		@include breakpoint-up(md) {
			width: 55px;
		}

		@include breakpoint-up(lg) {
			width: 60px;
		}
	}

	&-wnetwork {
		width: 36px;
		margin-bottom: 5px;

		@include breakpoint-up(md) {
			width: 42px;
		}

		@include breakpoint-up(lg) {
			width: 50px;
		}
	}

	&-slice {
		width: 45px;

		@include breakpoint-up(md) {
			width: 58px;
		}

		@include breakpoint-up(lg) {
			width: 70px;
		}
	}

	&-history {
		width: 35px;

		@include breakpoint-up(md) {
			width: 42px;
		}

		@include breakpoint-up(lg) {
			width: 50px;
		}
	}

	&-home {
		width: 45px;

		@include breakpoint-up(md) {
			width: 58px;
		}

		@include breakpoint-up(lg) {
			width: 65px;
		}
	}

	&-flavour {
		width: 60px;

		@include breakpoint-up(md) {
			width: 75px;
		}

		@include breakpoint-up(lg) {
			width: 90px;
		}
	}

	&-adultswim {
		margin-left: 3px;
		width: 70px;

		@include breakpoint-up(md) {
			width: 105px;
		}
	}

	&-natgeo {
		margin-left: 2px;
		width: 65px;

		@include breakpoint-up(md) {
			width: 90px;
		}
	}

	&-magnolia {
		margin-left: 3px;
		width: 70px;

		@include breakpoint-up(md) {
			width: 105px;
		}
	}

	&-ytv{
		width: 32px;

		@include breakpoint-up(md) {
			width: 52px;
		}

	}
}

.ShowSection-title {
	animation: swipe-in 0.7s ease-in-out;
	font-family: $title-font;
	font-size: 26px;
	font-weight: $font-weight-normal;
	margin: 8px 0;
	max-width: 40%;
	line-height: 1.2;

	@include breakpoint-up(md) {
		font-size: 35px;
	}
}

.ShowSection-metadata {
	margin-bottom: 10px;
}

.ShowSection-description {
	animation: swipe-in 0.7s ease-in-out 0.1s;
	color: $color-darkgrey;
	font-size: 11px;
	letter-spacing: 0.3px;
	line-height: 1.35;
	text-shadow: $text-drop-shadow;
	width: 33%;

	@include breakpoint-up(md) {
		font-size: 13px;
		letter-spacing: 0.5px;
		line-height: 1.45
	}

	@include breakpoint-up(lg) {
		font-size: 16px;
	}
}

.ShowSection-item-meta {
	color: $color-darkgrey;
}

.ShowSection-description {
	animation: swipe-in 0.7s ease-in-out 0.1s;
	&--text{
		transition: height 0.4s cubic-bezier(.07,.95,.69,.88);
	}

	&--less{
		&::after{
			@include cssArrow(up, 1px solid $body-font-color, 6px, true);
		}

	}

	&--more{
		&::after{
			@include cssArrow(down, 1px solid $body-font-color, 6px, true);
		}
	}
	&--more, &--less{
		color: $body-font-color;
		cursor: pointer;
		font-weight: $font-weight-medium;
		font-size: 12px;
		text-transform: uppercase;
		&::after{
			content: '';
			left: 16px;
			pointer-events: none;
			position: relative;
			top: 2px;
			transition: all 0.3s ease-in;
		}
	}
}

.ShowSection-watch-buttons {
	display: flex;
	margin-bottom: 20px;
	a {
		margin-right: 20px;
	}
	div.button-primary {
		display: inline-block;
		margin-right: 20px;
	}
}

.Image-loading,
.Image-loading::before,
.Image-loading::after {
	animation-play-state: paused !important;
}

.Movie-play-button {
	font-size: 14px;
	margin-right: auto;
	width: auto;
}

.ShowSection-item-meta {
	display: flex;
	flex-direction: row;
}

.ShowSection-button {
	background-color: red;
	padding: 12px;
	margin-right: 20px;
	display: flex;
    align-items: center;
	justify-content: center;
	width: 424px;
	height: 40px;
	font-size: 16px;
	font-weight: 600;

	img {
		width: 14px;
		max-height: 14px;
		margin-right: 8px;
	}
}

.shufflePlayButton {
	width: 136px;
}

.child-collections-wrapper, .season-items-wrapper {
	padding-left: var(--body-margin);
	font-weight: $font-weight-medium;
	z-index: 1;
}

.child-collections, .child-seasons {
	.inline-buttons {
		display: flex;
		list-style: none;
		margin-left: 0;
		padding: 0;
	}

	.inline-buttons li:hover, .inline-buttons li.active {
		color: $body-font-color;
	}
}

.child-collections {
	background-color: $body-background;
	padding: 1px;

	.inline-buttons li {
		color: $body-font-faded-color;
		background-color: $body-background;
		min-width: unset;
	}
}

.child-seasons {
	background-color: none;

	.inline-buttons li {
		color: $body-font-faded-color;
		min-width: unset;
	}
}