@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.VideoTile {
	font-size: 12px;

	@include breakpoint-up(md) {
		font-size: 14px;
	}

	@include breakpoint-up(lg) {
		font-size: 18px;
	}
}

.VideoTile-imageArea {
	position: relative;
}

.VideoTile-imageOverlay {
	background: linear-gradient(to top, rgba($body-background, 0.9), transparent);
	bottom: 0;
	left: 0;
	height: 50%;
	opacity: 0;
	position: absolute;
	right: 0;
	transition: opacity $default-transition;

	.VideoTile:hover & {
		opacity: 1;
	}
}

.VideoTile-playIcon {
	background: rgba(white, 0.6) url(../../../../assets/icons/tile-video-play.svg) no-repeat 57.5% center;
	background-size: 16px 16px;
	border-radius: 50%;
	bottom: 12px;
	height: 32px;
	left: 12px;
	position: absolute;
	width: 32px;

	@include breakpoint-up(md) {
		background-size: auto;
		height: 48px;
		width: 48px;
	}
}

.VideoTile-duration {
	bottom: 10px;
	position: absolute;
	right: 12px;
}

.VideoTile-image {
	display: block;
	width: 100%;
}

.VideoTile-label {
	font-weight: $font-weight-medium;
	letter-spacing: 0.7px;
	line-height: 1.2;
	overflow: hidden;
	min-height: 20px;
}

.VideoTile-info-loader {
	&--true {
		.VideoTile-info {
			display: none;
		}
	}

	&--false {
		.VideoTile-info {
			color: $body-font-faded-color;
			font-size: 13px;
			position: relative;
			font-weight: $font-weight-normal;


			& + & {
				&::before {
					content: '|';
					margin: 0 6px;
					vertical-align: top;
				}
			}

			@include breakpoint-up(lg) {
				font-size: 14px;
			}
		}
	}
}

.VideoTile-description {
	margin: 8px 0 4px;
}

.descType--long,
.descType--short {
	transition: height 0.4s cubic-bezier(.07,.95,.69,.88);
}

.VideoTile-description, .VideoTile-date {
	color: $body-font-faded-color;
	font-size: 11px;
	line-height: 1.4;
	font-weight: $font-weight-normal;


	&.isLoader--true {
		display: none;
	}

	@include breakpoint-up(md) {
		font-size: 12px;
	}

	@include breakpoint-up(lg) {
		font-size: 14px;
	}
}

.VideoTile-ReadMore--more, .VideoTile-ReadMore--less {
	cursor: pointer;
	font-weight: $font-weight-medium;
	font-size: 12px;
	text-transform: uppercase;

	&.isLoader--true {
		display: none;
	}
}

.VideoTile-ReadMore--more {
	animation: fade-in 0.5s ease forwards;
}

.VideoTile-ReadMore--more::after {
	@include cssArrow(down, 1px solid $body-font-color, 6px, true);
	content: '';
	left: 16px;
	pointer-events: none;
	position: relative;
	top: 2px;
	transition: all 0.3s ease-in;
}

.VideoTile-ReadMore--less::after {
	@include cssArrow(up, 1px solid $body-font-color, 6px, true);
	content: '';
	left: 16px;
	pointer-events: none;
	position: relative;
	top: 1px;
	transition: all 0.3s ease-in;
}

.Video-tile-wrap {
	cursor: pointer;
}

.VideoTile-sign-in-cta {
	background-color: rgba($cta-bg-color, 0.6);
	bottom: 15px;
	color: $body-font-color;
	font-size: 10px;
	left: 15px;
	letter-spacing: 0.5px;
	padding: 10px;
	position: absolute;
	text-transform: uppercase;
	border: 1px solid transparent;
	transition: 0.2s ease-in;

	@include breakpoint-up(md) {
		font-size: 12px;
	}

	.VideoTile-imageArea:hover & {
		background: transparent;
		border: 1px solid $cta-bg-color;
	}
}

.VideoTile-overlay {
	background: linear-gradient(to top, rgba($body-background, 0.9), transparent);
	bottom: 0;
	left: 0;
	height: 50%;
	position: absolute;
	right: 0;
	transition: all $default-transition;

	.VideoTile-imageArea:hover & {
		background: linear-gradient(to top, rgba(darken($body-background, 5%), 1), transparent);
	}
}

.VideoTile-imageArea-loader {
	&.VideoTile-imageArea {
		animation: pulse 2s ease-in infinite;
		min-height: 195px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $loader-bg-color;
	}
}

.VideoTile-label-loader {
	animation: pulse 2s ease-in infinite;
	background: $loader-bg-color;
	border-radius: 10px;
	content: "";
	height: 10px;
	margin: 0.4em 0em 0.15em;
	position: relative;
	width: 80%;
}

.VideoTile-info-loader--true {
	@extend .VideoTile-label-loader;
	width: 50%;
}

.VideoTile-progress-container {
	background: $body-font-color;
	height: 2px;
	margin-top: 10px;
	width: 100%;

	.VideoTile-progress-bar {
		background: $globaltv-red;
		height: 100%;
	}

}

.VideoTile-info-container {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.VideoTile-schedule-container{
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.VideoTile-newIndicator {
	color: black;
	background-color: white;
	padding-left: 2.5px;
	padding-right: 2.5px;
	padding-top: 0.2px;
	padding-bottom: 0.2px;
	margin-right: 5px;
	font-weight: 600;
	font-size: 11px;
}
