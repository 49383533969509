@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';
@import 'src/styles/shared-styles';


.smartPlay-container{
    display: flex;
    flex-direction: column;
	width: 424px;
    margin-right: 16px;

    .ShowSection-button {
        padding: 12px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: 600;

        img {
            width: 14px;
            max-height: 14px;
            margin-right: 8px;
        }


    }
    .VideoTile-progress-container {
        background: $body-font-color;
        height: 6px;
        margin-top: 4px;
        width: 100%;

        .VideoTile-progress-bar {
            background: $globaltv-red;
            height: 100%;
        }
    }
}

.smartPlayButton {
	width: 302px;

    + .ShowSection-button {
        width: 136px;
    }
}



