@import "src/styles/mixins";
.AppInfo-textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .textBox-heading {
    color: var(--hero-red, #ef2601);
    font-size: 20px;
    font-weight: 500;
    margin: 0;

    @include breakpoint-up(sm)  {
      font-size: 24px;
    }

  }
  .textBox-subHeading {
    font-size: 16px;
    margin: 0;

    @include breakpoint-up(sm)  {
      font-size: 20px;
      margin: 0;

    }
  }
}

.AppInfo-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  padding: 0px;
}
.AppInfo-description {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  p{
    margin: 0px;
  }
}
