@import 'src/styles/variables';

.datatable {
    width: 100%;
    margin: auto;
    color: white;
    background: black;
    overflow-x: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.datatable_head {
    position: relative;
    z-index: 3;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .datatable_time_indicator {
        background: black !important;
        color: white !important;
        font-weight: 400;
        padding: 0;
        z-index: 1;
        width: 10px;
        padding: 16px 0;
    }
}
.datatable_select_head {
    display: flex;
    color: white !important;
    background: black !important;
    cursor: pointer;
    height: 51px;
}

.channel_column {
    background: black !important;
    color: white !important;
    display: flex;
    cursor: pointer;
    padding-left: 20px;
    min-width: 160px;
}
.shows_body {
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.schedule__data {
    padding: 20px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    text-align: left;
    max-width: 212px;
    color: #f2f2f2 !important;
    background: #231f20;
    &:hover {
        opacity: 1;
        background: #5e5e5e;
        cursor: pointer !important;
    }
}
.schedule__data__inactive {
    padding: 20px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 212px;
    background: #141414;
    color: #f2f2f2 !important;
    border: 1px solid #000000;
    opacity: 0.5;
    cursor: default;
}
.mdc-data-table__cell {
    background-clip: padding-box;
}
.schedule__data-selected {
    padding: 20px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $font-weight-normal;
    border: 1px solid #000000;
    text-align: left;
    max-width: 212px;
    color: #f2f2f2 !important;
    background: #5e5e5e;
}

.container {
    height: 10px;
    background-color: white;
    position: relative;

    .progress_Bar {
        position: absolute;
        height: 100%;
        background-color: red;
    }
}

.time_indicator_bar {
    position: absolute;
    top: 48px;
    z-index: 1;
    width: 210px;
}

.allowWidth {
    width: auto !important;
}
/** DataTable Sticky Rules */
.rmwc-data-table--sticky-columns-1 .rmwc-data-table__cell:nth-child(-n + 1) {
    display: table-cell;
    left: 0;
    z-index: 1;
    box-shadow: 1px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--mdc-theme-surface, white);
}

.show_metadata_row {
    border-top: 2px solid #f2f2f2 !important;
    border-bottom: 2px solid #f2f2f2;
    transition: height 1s ease-in-out;
}
.show_metadata_cell {
    display: flex;
    border-top: 2px solid rgb(242, 242, 242) !important;
    border-bottom: 2px solid rgb(242, 242, 242);
    padding-left: 170px;
    position: sticky;
    left: 0px;
}
.show__metadata {
    display: flex;
    width: 100%;
    position: relative;
}

.mdc-linear-progress__buffering-dots {
    display: none;
}
