@import 'src/styles/variables';
@import 'src/styles/mixins';

.GridTileArea {
	align-items: end;
	display: grid;
	grid-auto-flow: row dense;
	grid-gap: var(--grid-gap);
	grid-template-columns: repeat(var(--grid-columns), 1fr);
	padding: 0;
}

.GridTileArea .ShowTile {
	transition: all $default-transition;

	// Pop-in animation for tile slides.
	animation: 0.6s ease-in-out both fade-in;
	display: block;
	opacity: 0;

	@for $i from 1 to 100 {
		&:nth-child(#{$i}) > * {
			animation-delay: $i * (.025s);
		}
	}
}
