@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.Header {
	align-items: center;
	justify-content: space-between;
	display: flex;
	flex: 0 0 auto;
	height: 64px;
	padding: 0 var(--body-margin);
	
	@include breakpoint-up(sm) {
		margin-bottom: 50px;
		height: 80px;	}

	@include breakpoint-up(md) {
		height: 140px;
	}
}

.Header.withRotator {
	background: $site-gradient;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 999;
}

.Header-logo-link {
	margin-bottom: 10px;
	margin-right: 36px;
	width: 86px;
	margin-left: auto;
	margin-right: auto;

	@include breakpoint-up(md) {
		margin-bottom: 15px;
		margin-right: 46px;
		width: 100px;
		margin-left: unset !important;
	}

	@include breakpoint-up(lg) {
		margin-right: 56px;
		width: 135px;
	}

	&:focus {
		outline: none;
	}
}

.Header-nav {
	align-items: center;
	display: none;
	height: 42px;

	&--right {
		margin-left: auto;
	}

	@include breakpoint-up(md){
		display: flex;
	}
}

.AppStore-nav{
	display: block;
	width: 120px;
	margin-right: 0px !important;

	@include breakpoint-up(md){
		display: none;
	}
}

.Header-nav-item {
	color: $link-faded-color;
	font: $font-weight-medium 10px $title-font;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: color $default-transition;

	@include breakpoint-up(md) {
		font-size: 14px;
	}

	@include breakpoint-up(lg) {
		font-size: 16px;
	}

	& + & {
		margin-left: 3em;
	}

	&--schedule-shared::after {
		background-color: $globaltv-red;
		border-radius: 50%;
		content: '';
		display: inline-block;
		height: 7px;
		margin: 0 0 0 0.5em;
		width: 7px;

		@include breakpoint-up(md) {
			height: 10px;
			width: 10px;
		}
	}

	&.active {
		color: $body-font-color;
		font-weight: 500;
	}

	&:hover {
		color: $body-font-color;
	}

	&:focus {
		outline: none;
	}

	.SignIn-Button & {
		padding-right: 0;
	}
}

.free-shared-true::after {
	background-color: $globaltv-red;
	position: absolute;
	content: 'new';
	display: inline-block;
	top:-12px;
	right: 0;
	color: #ffffff;
	font-size: 9px;
	height: 11px;
	font-weight: 350;
	margin: 0 0 0 0.3em;
	line-height: 10px;
	padding: 1px;
}