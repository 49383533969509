@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.ContinueWatching {

	.VideoTileArea {
		min-height: auto;
		.header{
			display: flex;
			align-items: center;
			.Close-icon-box{
				animation: zoom-in 0.3s ease-in;
				background-color:$close-font-faded-color;
				cursor:pointer;
				border-radius: 24px;
				margin-left: 15px;
				margin-top:3px;
				width: 24px;
				height: 24px;
				opacity:0.9;
				text-align:center;
				overflow: hidden;
				.Close-icon{
					color:$body-font-color;
					line-height: 9px;
					letter-spacing: 0.4px;
					text-align:center;
					text-transform: uppercase;
					font: 12px $proxima-nova;
					font-weight: bold;
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2){
						font: 12px $body-font-family;
					}
				}

				&:hover{
					width: 71px;
					display: flex;
					justify-content: center;
					align-items: center;
					.Close-icon:nth-child(1) {
						display: block;
					}
					.Close-icon:nth-child(2){
						padding-left: 4px;
					}
				}
			}
		}
	}

	.VideoTile-date {
		display: none;
	}
}
