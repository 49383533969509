@import 'src/styles/mixins';
@import 'src/styles/variables';

.BrandBar {
	text-align: center;
    align-self: center;
	padding: 14px var(--body-margin);

	> .TileSlider{
		z-index: 1; // For stacking over the show section, where applicable
	}
}
.BrandBar-title{
	color: #F2F2F2;
	margin: 0 var(--body-margin);
	font-family: $title-font-two ;
	font-weight: $font-weight-medium;
	margin-bottom: 16px;
	letter-spacing: -0.03 px;

	@include breakpoint-up(xs) {
		font-size: 13px;
	}
	@include breakpoint-up(md) {
		font-size: 18px;
	}
}

.BrandBar-link {
	animation: fade-in-with-opacity 0.6s ease-in;
	background: #282828;
    color: #B7B7B7;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
	height: 64px;
	padding: 24px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
	opacity: 0.4;

    &:hover {
        background: #000000;
        color: #F2F2F2;
		opacity: 1;
	}
    &.active{
        background: #141414;
        color: #F2F2F2;
		opacity: 1;
    }

	@include breakpoint-up(xs) {
		padding: 20px;
		height: 56px;
	}
	@include breakpoint-up(md) {
		padding: 20px;
		height: 64px;
	}
}


.BrandBar-link-image {
	height: auto;
	margin: 0 12px;
    object-fit: contain;
    max-width: 60%;

	@include breakpoint-up(sm) {
		margin: 0 14px;
		max-width: 60%;

	}

	@include breakpoint-up(md) {
		margin: 0 28px;
		max-width: 70%;

	}

	@include breakpoint-up(lg) {
		margin: 0 32px;
		max-width: 90%;

	}

	&.BrandBar-natgeo,
	&.BrandBar-adultswim {
		margin: 0 8px;

		@include breakpoint-up(sm) {
			margin: 0 9px;
		}

		@include breakpoint-up(md) {
			margin: 0 22px;
		}

		@include breakpoint-up(lg) {
			margin: 0 24px;
		}
	}
}

.BrandBar-global {
	width: 48px;

	@include breakpoint-up(sm) {
		width: 48px;
	}

	@include breakpoint-up(md) {
		width: 64px;
	}

	@include breakpoint-up(lg) {
		width: 75px;
	}
}

.BrandBar-wnetwork {
	width: 30px;

	@include breakpoint-up(sm) {
		width: 34px;
	}

	@include breakpoint-up(md) {
		width: 44px;
	}

	@include breakpoint-up(lg) {
		width: 52px;
	}
}

.BrandBar-history {
	width: 23px;

	@include breakpoint-up(sm) {
		width: 28px;
	}

	@include breakpoint-up(md) {
		width: 35px;
	}

	@include breakpoint-up(lg) {
		width: 40px;
	}
}

.BrandBar-showcase {
	width: 36px;

	@include breakpoint-up(sm) {
		width: 38px;
	}

	@include breakpoint-up(md) {
		width: 48px;
	}

	@include breakpoint-up(lg) {
		width: 56px;
	}
}

.BrandBar-slice {
	width: 36px;

	@include breakpoint-up(sm) {
		width: 38px;
	}

	@include breakpoint-up(md) {
		width: 54px;
	}

	@include breakpoint-up(lg) {
		width: 64px;
	}
}

.BrandBar-home {
	width: 36px;

	@include breakpoint-up(sm) {
		width: 36px;
	}

	@include breakpoint-up(md) {
		width: 51px;
	}

	@include breakpoint-up(lg) {
		width: 62px;
	}
}

.BrandBar-flavour {
	width: 60px;

	@include breakpoint-up(sm) {
		width: 64px;
	}

	@include breakpoint-up(md) {
		width: 78px;
	}

	@include breakpoint-up(lg) {
		width: 90px;
	}
}

.BrandBar-adultswim,
.BrandBar-natgeo {
	width: 60px;

	@include breakpoint-up(sm) {
		width: 64px;
	}

	@include breakpoint-up(md) {
		width: 78px;
	}

	@include breakpoint-up(lg) {
		width: 90px;
	}
}

.LiveTV {
	.BrandBar-global {
		width: 25px;

		@include breakpoint-up(sm) {
			width: 48px;
		}

		@include breakpoint-up(md) {
			width: 44px;
		}

		@include breakpoint-up(lg) {
			width: 64px;
		}
	}

	.BrandBar-wnetwork {
		width: 20px;

		@include breakpoint-up(sm) {
			width: 23px;
		}

		@include breakpoint-up(md) {
			width: 32px;
		}

		@include breakpoint-up(lg) {
			width: 46px;
		}
	}

	.BrandBar-history {
		width: 20px;

		@include breakpoint-up(sm) {
			width: 23px;
		}

		@include breakpoint-up(md) {
			width: 32px;
		}

		@include breakpoint-up(lg) {
			width: 43px;
		}
	}

	.BrandBar-showcase {
		width: 24px;

		@include breakpoint-up(sm) {
			width: 30px;
		}

		@include breakpoint-up(md) {
			width: 42px;
		}

		@include breakpoint-up(lg) {
			width: 56px;
		}
	}

	.BrandBar-slice {
		width: 24px;

		@include breakpoint-up(sm) {
			width: 30px;
		}

		@include breakpoint-up(md) {
			width: 42px;
		}

		@include breakpoint-up(lg) {
			width: 56px;
		}
	}

	.BrandBar-hgtv {
		width: 22px;

		@include breakpoint-up(sm) {
			width: 28px;
		}

		@include breakpoint-up(md) {
			width: 32px;
		}

		@include breakpoint-up(lg) {
			width: 48px;
		}
	}

	.BrandBar-food {
		width: 20px;

		@include breakpoint-up(sm) {
			width: 23px;
		}

		@include breakpoint-up(md) {
			width: 32px;
		}

		@include breakpoint-up(lg) {
			width: 46px;
		}
	}

	.BrandBar-globalnewsott {
		width: 24px;

		@include breakpoint-up(sm) {
			width: 30px;
		}

		@include breakpoint-up(md) {
			width: 44px;
		}

		@include breakpoint-up(lg) {
			width: 62px;
		}
	}

	.BrandBar-adultswim,
	.BrandBar-natgeo {
		width: 30px;

		@include breakpoint-up(sm) {
			width: 38px;
		}

		@include breakpoint-up(md) {
			width: 52px;
		}

		@include breakpoint-up(lg) {
			width: 77px;
		}
	}
}
.BrandBar-freeplay {
	width: 56px;

	@include breakpoint-up(sm) {
		width: 64px;
	}

	@include breakpoint-up(md) {
		width: 68px;
	}

	@include breakpoint-up(lg) {
		width: 75px;
	}
}