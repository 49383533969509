@import './keyframes';

// Generic colours.
$color-grey: #808080;
$color-grey-10: #202020;
$color-grey-25: #3e3e3e;
$color-grey-50: $color-grey;
$color-grey-75: #bebebe;
$color-darkgrey: #A9A9A9;


// Font weights.
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;


// Site-specific variables.

/// Breakpoints.
$breakpoints: (
	xs: 0,
	sm: 576px,
	mm: 750px,
	md: 992px,
	lg: 1200px,
	xm: 1400px,
	xl: 1640px
);

$max-content-well-width: 1440px;

/// CSS variable default values, for use in calculations.
$css-variables: (
	--body-margin: (
		xs: 3%,
		sm: 3%,
		md: 5%,
		lg: 7%,
		xl: calc((100vw - #{$max-content-well-width})/2)
	),
	--grid-columns: (
		xs: 3,
		sm: 4,
		md: 5,
		lg: 6,
		xl: 6
	),
	--grid-gap: (
		xs: 6px,
		md: 12px,
	),
);

/// Fonts and colours.
$body-background: #141414;
$body-font-family: 'Red Hat Text', sans-serif;
$proxima-nova: proxima-nova;
$body-font-color: #F2F2F2;
$body-font-faded-color: #999999;
$close-font-faded-color: rgba(153, 153, 153, 0.3);
$title-font: 'Red Hat Text', sans-serif;
$title-font-two: 'Red Hat Text', sans-serif ;
$link-faded-color: #ADADAD;
$globaltv-red: #ef2601;
$cta-bg-color: #B7B7B7;
$premium-brand-cta-bg: #202020;
$loader-bg-color: #212121;
$focus-outline-color: #FFFFFF;
$authmodal-font-color: #101010;

/// Miscellaneous behaviours/spacing.
$default-transition: .2s ease-out;
$default-fade-in: fade-in 0.5s ease-in;
$site-gradient: linear-gradient(0deg, rgba(0,0,0,0) 0%, #141414 100%);
$text-drop-shadow: 2px 2px 7px #303030;

