@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.OnNowOnNext {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2%;
	margin: 0 var(--body-margin) 30px;

	&-onNow, &-comingUp {
		padding: 10px 20px;

		@include breakpoint-up(md) {
			padding: 19px 27px;
		}

		&-title {
			font-size: 10px;
			font-weight: $font-weight-bold;
			letter-spacing: .3px;
			line-height: 20px;
			margin-bottom: 8px;
			text-transform: uppercase;

			@include breakpoint-up(md) {
				font-size: 16px;
				margin-bottom: 18px;
			}
		}
	}

	&-onNow {
		background-color: $color-grey-10;

		&-details {
			font-weight: $font-weight-semi-bold;
			font-size: 16px;
			letter-spacing: .4px;
			line-height: 1.3;
			margin-bottom: 6px;

			@include breakpoint-up(md) {
				font-size: 24px;
				line-height: 1;
				margin-bottom: 10px;
			}
		}

		&-time {
			font-size: 12px;
			letter-spacing: .4px;
			line-height: 16px;

			@include breakpoint-up(md) {
				font-size: 16px;
				letter-spacing: .5px;
			}
		}
	}

	&-comingUp {
		border: 1px solid $color-grey-25;

		&-item {
			display: flex;
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 3px;

			@include breakpoint-up(md) {
				font-size: 16px;
				letter-spacing: .5px;
				margin-bottom: 10px;
			}
		}

		&-item-time {
			flex: 0 0 6em;
			letter-spacing: .4px;
		}

		&-item-showName {
			flex: 1 1 auto;
			font-weight: $font-weight-bold;
			letter-spacing: .2px;
		}

		&-item-time, &-item-showName {
			color: $body-font-faded-color;
			display: inline-block;
		}
	}
}

.OnNowOnNext-outer {
	border: 1px solid #3e3e3e;
	height: auto;
	width: 100%;

	.OnNowOnNext-inner {
		animation: pulse 2s ease infinite;
		margin: 20px;

		@include breakpoint-up(md) {
			margin: 30px;
		}
	}

	.Thick-line {
		@include createLine($height: 15px, $radius:8px, $width:40%);
		margin-bottom: 15px;

		@include breakpoint-up(md) {
			@include createLine($height: 20px, $radius:8px, $width:40%);
			margin-bottom: 25px;
		}
	}

	.Thin-line {
		@include createLine($height: 8px, $width:70%);
		margin: 8px 0;

		@include breakpoint-up(md) {
			@include createLine($height: 10px, $width:60%);
			margin: 12px 0;
		}
	}
}
