@import 'src/styles/variables';
@import 'src/styles/mixins';

.Hero-content-wrapper {
	align-items: flex-start;
	bottom: 25%;
	display: flex;
	flex-direction: column;
	font-family: $title-font;
	font-weight: $font-weight-extra-light;
	justify-content: flex-start;
	left: var(--body-margin);
	position: absolute;
	width: 40%;

	@include breakpoint-up(md) {
		width: 32%;
	}

	.Hero-brand-logo {
		height: auto;

		&-global {
			margin-bottom: 5px;
			width: 56px;

			@include breakpoint-up(md) {
				margin-bottom: 10px;
				width: 70px;
			}

			@include breakpoint-up(lg) {
				width: 120px;
			}
		}
		&-lifetime {
			margin-bottom: -3px;
			width: 75px;

			@include breakpoint-up(md) {
				margin-bottom: 3px;
				width: 100px;
			}

			@include breakpoint-up(lg) {
				margin-bottom: 5px;
				width: 120px;
			}
		}

		&-magnolia {
			margin-bottom: -3px;
			width: 75px;

			@include breakpoint-up(md) {
				margin-bottom: 3px;
				width: 100px;
			}

			@include breakpoint-up(lg) {
				margin-bottom: 5px;
				width: 130px;
			}
		}

		&-globalnewsott {
			margin-bottom: -10px;
			margin-left: -5px;
			width: 56px;

			@include breakpoint-up(md) {
				margin-bottom: -15px;
				width: 75px;
			}

			@include breakpoint-up(lg) {
				margin-bottom: -10px;
				width: 100px;
			}
		}

		&-home {
			margin-bottom: 0;
			width: 45px;

			@include breakpoint-up(md) {
				margin-bottom: -5px;
				width: 60px;
			}

			@include breakpoint-up(lg) {
				width: 84px;
			}
		}

		&-showcase,
		&-slice {
			margin-bottom: -5px;
			width: 45px;

			@include breakpoint-up(md) {
				margin-bottom: -10px;
				width: 56px;
			}

			@include breakpoint-up(lg) {
				margin-bottom: -5px;
				width: 75px;
			}
		}

		&-history,
		&-wnetwork {
			width: 36px;

			@include breakpoint-up(md) {
				width: 42px;
			}

			@include breakpoint-up(lg) {
				width: 60px;
			}
		}

		&-wnetwork {
			margin-bottom: 5px;
		}
		
		&-flavour,
		&-adultswim,
		&-natgeo {
			margin-bottom: 0;
			width: 56px;

			@include breakpoint-up(md) {
				width: 85px;
			}

			@include breakpoint-up(lg) {
				margin-bottom: 5px;
				width: 120px;
			}
		}

		&-ytv{
			height: 32px;

			@include breakpoint-up(md) {
				height: 48px;
			}

		}
	}

	.Hero-show-name {
		font-size: 25px;
		font-weight: $font-weight-normal;
		margin: 10px 0;
		text-shadow: $text-drop-shadow;

		@include breakpoint-up(md) {
			font-size: 35px;
			line-height: 1.1;
		}

		@include breakpoint-up(lg) {
			font-size: 50px;
		}
	}

	.Hero-show-desc {
		font-family: $body-font-family;
		font-size: 12px;
		font-weight: $font-weight-normal;
		letter-spacing: 0;
		margin-top: 0px;
		text-shadow: $text-drop-shadow;

		@include breakpoint-up(md) {
			font-size: 15px;
		}

		@include breakpoint-up(lg) {
			font-size: 18px;
		}
	}
}

.HeroContent-enter {
	transition: all 1.25s ease-in ;
	opacity: 0;

	&.HeroContent-enter-active {
		opacity: 1;
	}
}

.Hero-watch-button {
	@include breakpoint-up(md) {
		font-size: 12px;
	}

	@include breakpoint-up(lg) {
		font-size: 16px;
	}
}
