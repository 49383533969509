@import 'src/styles/keyframes';
@import 'src/styles/variables';

.SignIn-Button {
	animation: fade-in 0.3s ease-in;
}

.SignIn-popup-wrapper {
	padding: 40px 0;
	position: relative;
}

.channel-list-wrapper {
	animation: smooth-slide-down 0.35s ease-in;
	background: $body-font-color;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
	color: $body-background;
	height: auto;
	margin-top: 25px;
	min-height: 200px;
	padding: 20px;
	position: absolute;
	right: 0;
	text-align: center;
	width: 280px;
	z-index: 6;

	&::after {
		border-color: transparent transparent $body-font-color transparent;
		border-style: solid;
		border-width: 12px;
		bottom: 100%;
		content: " ";
		right: 10%;
		position: absolute;
	}
}

.current-bdu-title {
	font-size: 14px;
	margin-top: 16px;
}

.channel-list-title {
	font-size: 14px;
}

.current-bdu-logo {
	height: auto;
	margin: 8px 0;
	width: 120px;
}

.channel-list-container {
	padding: 0;
}

.channel-list-item {
	font-size: 14px;
	list-style: none;
	margin: 3px 0;

	a {
		color: $body-background;

		&:hover {
			text-decoration: underline;
		}
	}
}

// Adjust the layout when user doesn't have access to any of the channels
.zero-channels {
	&--true {
		margin-top: -20px;
	}
}

.Signout-btn {
	font-size: 14px;
	letter-spacing: 0.75px;
	margin-top: 10px;
	padding: 12px 18px;

	&:hover {
		color: $globaltv-red;
	}
}

.no-channel-access {
	font-size: 15px;
	margin: 20px 0px;
}