@import 'src/styles/variables';
@import 'src/styles/mixins';

.AgeGateModal-wrapper {
	align-items: center;
	background-color: rgba(0,0,0,0.4);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	touch-action: none;
	width: 100%;
	z-index: 999999;
	flex-direction: column;
}

.AgeGateModal {
	animation: zoom-in 0.4s ease forwards;
	background: rgba(0,0,0,1);
	border-radius: 5px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
	color: $body-font-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 550px;
	padding: 60px 10px;
	text-align: center;
	width: auto;

	@include breakpoint-up(sm) {
		padding: 60px 80px;
	}
}

.AgeGateModal-desc {
	font-size: 17px;
	letter-spacing: 0.2px;
	line-height: 1.4;
}

.AgeGateModal-date {
	margin: 25px auto 0 auto;
	text-align: center;
}

.AgeGateModal-input {
	background-color: $body-background;
	border-color: transparent;
	color: $color-grey;
	font-size: 14px;
	padding: 8px;
	text-align: center;
}

.AgeGateModal-day, .AgeGateModal-month {
	width: 50px;
}

.AgeGateModal-year {
	width: 100px;
}

.AgeGateModal-date-input {
	margin-right: 10px;
}

.AgeGateModal-date-input:last-of-type {
	margin-right: 0;
}

.AgeGateModal-province {
	color: $color-grey;
	background-color: $body-background;
	border-color: transparent;
	display: block;
	height: 40px;
	letter-spacing: 0.4px;
	margin: 15px auto 0;
	padding: 10px;
	font-size: 15px;
	text-align-last: center;
	width: 222px;
}

.AgeGateModal-remember {
	margin-top: 30px;
	text-align: center;
}

.AgeGateModal-rememberCheckbox {
	display: inline-block;
	vertical-align: top;
}

.AgeGateModal-rememberDesc {
	display: inline-block;
}

.AgeGateModal-rememberDesc-paragraph {
	margin: 0 auto auto 5px;
	margin-bottom: 0;
	text-align: left;
	font-size: 14px;
}

.AgeGateModal-rememberDesc-paragraph:first-child {
	margin-bottom: 3px;
}

.AgeGateModal-close {
	font-size: 40px;
	position: absolute;
	top: 2px;
	right: 10px;
}

input.AgeGateModal-send {
	background-color: $globaltv-red;
	border: 2px solid $globaltv-red;
	color: $body-font-color;
	cursor: pointer;
	font-size: 15px;
	letter-spacing: 0.5px;
	margin-top: 25px;
	padding: 10px 25px;
	text-transform: uppercase;
	transition: $default-transition;

	&:hover {
		background-color: transparent;
	}
}

.AgeGateModal-error {
	color: $globaltv-red;
	margin-bottom: 0;
}