@import 'src/styles/mixins';
@import 'src/styles/variables';

.Dropdown {
	margin-right: auto;
	position: relative;

	&::after {
		@include cssArrow(down, 1px solid $body-font-color, 7px, true);
		content: '';
		left: -14px;
		pointer-events: none;
		position: relative;
		top: 2px;
	}

	&:focus {
		outline: none;
	}
}

.Dropdown-select, .inline-buttons li {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border: none;
	color: $body-font-color;
	cursor: pointer;
	font-family: $title-font;
	font-size: 9px;
	letter-spacing: 0.5px;
	min-width: 115px;
	padding: 8px 30px 8px 8px;

	@include breakpoint-up(md) {
		font-size: 14px;
		min-width: 140px;
		padding: 12px 30px 12px 12px;
	}

	&:focus {
		outline: none;
	}
}
