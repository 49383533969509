@import 'src/styles/variables';
@import 'src/styles/mixins';

.Video {
	height: 100vh;
	overflow: hidden;
	width: 100vw;
}

.Video-overlay {
	background: linear-gradient(0, transparent 80%, rgba(0, 0, 0, 0.7) 100%, #000);
	height: 100%;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: opacity, visibility 250ms cubic-bezier(0, .25, .25, 1);
	width: 100%;
	z-index: 1000;

	// Apologies for this query, it's taken from JWPlayer. Hides while playback is active.
	.jw-flag-user-inactive.jw-state-playing:not(.jw-flag-media-audio):not(.jw-flag-audio-player):not(.jw-flag-ads-vpaid-controls):not(.jw-flag-casting) ~ & {
		opacity: 0;
		visibility: hidden;
	}
}

.Video-overlay-backButton {
	font-size: 36px;
	pointer-events: all;
	position: absolute;
	right: 25px;
	top: 20px;
	z-index: 1;

	@include breakpoint-up(sm) {
		font-size: 48px;
		right: 50px;
	}
}

.Video-overlay-helpButton {
	background: transparent;
	border: 2px solid $body-font-color;
	border-radius: 50%;
	color: $body-font-color;
	cursor: pointer;
	font-size: 21px;
	height: 29px;
	pointer-events: all;
	position: absolute;
	right: 60px;
	text-align: center;
	top: 26px;
	width: 29px;
	z-index: 1;

	@include breakpoint-up(sm) {
		font-size: 26px;
		height: 35px;
		right: 100px;
		top: 30px;
		width: 35px;
	}
}
