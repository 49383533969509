@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.Search {
	display: flex;
	max-width: 250px;
	position: relative;
	width: 250px;
}

.Search-box {
	animation: expand ease-in 0.3s;
	background: lighten($loader-bg-color, 1%);
	border: none;
	color: $link-faded-color;
	font-family: $body-font-family;
	font-size: 14px;
	font-weight: $font-weight-semi-bold;
	height: 45px;
	letter-spacing: 0.25px;
	margin-right: 20px;
	outline: none;
	padding: 10px;
	position: absolute;
	right: 0;
	top: -23px;
	transition: $default-transition;
	width: 100%;

	&::placeholder {
		color: $link-faded-color;
		font-weight: $font-weight-semi-bold;
		opacity: 1;
	}
}

.Search-box-icon {
	animation: zoom-in 0.3s ease-in;
	color: $link-faded-color;
	font-size: 30px;
	position: absolute;
	right: 26px;
	top: -21px;

	&:focus {
		outline: none;
	}

	.Search-close-icon {
		animation: zoom-in 0.3s ease-in;
		padding-top: 3px;
	}
}

.Search-icon-wrapper {
	animation: zoom-in 0.3s ease-in;
	margin-right: 30px;
	margin-top: 5px;
}

.Search-icon {
	color: $link-faded-color;
	height: 16px;
	transition: $default-transition;

	&:hover {
		color: white;
		cursor: pointer;
		font-size: 22px;
	}
}