@import 'src/styles/keyframes';

.Spinner {
	display: inline-block;
	font-size: 24px;
	height: 1em;
	position: relative;
	width: 1em;

	.Spinner-blade {
		background-color: transparent;
		border-radius: .0555em;
		bottom: 0;
		height: .2777em;
		left: .4629em;
		position: absolute;
		transform-origin: center -.2222em;
		width: .074em;

		$animation-delay: 0s;
		$blade-rotation: 0deg;

		@for $i from 1 through 12 {
			&:nth-child(#{$i}) {
				animation-delay: $animation-delay;
				transform: rotate($blade-rotation);
				$blade-rotation: $blade-rotation + 30;
				$animation-delay: $animation-delay + .083;
			}
		}
	}
}