%button-primary {
	background-color: $globaltv-red;
	border: 2px solid $globaltv-red;
	border-radius: 0;
	cursor: pointer;
	font-family: $body-font-family;
	font-size: 10px;
	font-weight: $font-weight-medium;
	letter-spacing: 0.5px;
	padding: 9px 14px;
	text-align: center;
	text-transform: uppercase;
	transition: $default-transition;

	&:hover {
        background-color: transparent;
        color: $body-font-color;
	}

	@include breakpoint-up(md) {
		font-size: 16px;
		padding: 10px 18px;
	}
}

%button-secondary {
	background-color: black;
	border: 2px solid white;
	border-radius: 0;
	cursor: pointer;
	font-family: $body-font-family;
	font-size: 10px;
	font-weight: $font-weight-medium;
	letter-spacing: 0.5px;
	padding: 9px 14px;
	text-align: center;
	text-transform: uppercase;
	transition: $default-transition;

	&:hover {
        background-color: white;
        color: black;
	}

	@include breakpoint-up(md) {
		font-size: 16px;
		padding: 10px 18px;
	}
}

.Closing-transition {
	animation: zoom-out 0.2s ease-out forwards;
}


