@import "src/styles/mixins";

.Faq-container {
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @include breakpoint-up(sm) {
    padding: 16px 24px;
  }
}
.Faq-heading {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
}

.Faq-subheading {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}

.Dropdown-Menu {
  display: flex;
  padding: 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--grey-lt-grey-97, #979797);
  background: var(--solid-white, #fff);
  color: #000;
  font-size: 16px;
  font-family: Red Hat Text;
  font-weight: 500;
  line-height: 24px;

  &.Dropdown-Menu--false:hover {
    cursor: pointer;
    border: 1px solid var(--hero-red, #ef2601);

    &::after {
      content: url("../../../assets/icons/DropdownIcon-hover_mobile.svg");
    }
  }

  &::after {
    content: url("../../../assets/icons/DropdownIcon_mobile.svg");
  }

  &.Dropdown-Menu--true {
    &:hover {
      cursor: pointer;
    }

    &::after {
      content: url("../../../assets/icons/DropdownIcon_mobile.svg");
      transform: rotate(180deg);
    }
  }

  @include breakpoint-up(sm) {
    font-size: 20px;
    padding: 16px 24px;

    &.Dropdown-Menu--false:hover {
      cursor: pointer;
      border: 1px solid var(--hero-red, #ef2601);

      &::after {
        content: url("../../../assets/icons/DropdownIcon-hover.svg");
      }
    }

    &::after {
      content: url("../../../assets/icons/DropdownIcon.svg");
    }

    &.Dropdown-Menu--true {
      &:hover {
        cursor: pointer;
      }

      &::after {
        content: url("../../../assets/icons/DropdownIcon.svg");
        transform: rotate(180deg);
      }
    }
  }
}

.Dropdown-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  border: 1px solid var(--grey-lt-grey-97, #979797);
  border-top: none;
  background: var(--solid-white, #fff);
  display: flex;
  flex-direction: column;
  padding-block: 16px;
  justify-content: space-between;
  align-self: stretch;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  height: fit-content;

  @include breakpoint-up(sm) {
    font-size: 20px;
  }
}
.Dropdown-option {
  display: flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  &:hover {
    opacity: 1;
    background: #c6c6c6;
    cursor: pointer !important;
  }

  &.active {
    font-weight: 500;
  }
}

.Question-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.Question-dialog {
  display: flex;
  position: relative;
  padding: 16px 16px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--hero-white, #f2f2f2);
  border-bottom: 1px solid #d9d9d9;

  @include breakpoint-up(sm) {
    padding: 24px 20px 24px 0px;
  }

  .Question-section:hover {
    cursor: pointer;
    animation: colorChange 1s ease; /* Use a CSS animation to change the color over 2 seconds */

    @include breakpoint-up(sm) {
      color: var(--hero-red, #ef2601);
      cursor: pointer;
      animation: none;
    }
  }


  &.Question-dialog--false {
    .Question-section {
      &::after {
        right: 12px;
        position: absolute;
        border-right: 3px solid #000;
        border-top: 3px solid #000;
        height: 10px;
        width: 10px;
        transform: rotate(225deg) translate(-16.7%, 16.7%);
        content: "";
        display: inline-block;
        margin: 0.5rem 0 0;
        transition: border-color 1s ease-out;

        @include breakpoint-up(sm) {
          right: 30px;
          border-right: 4px solid #000;
          border-top: 4px solid #000;
          height: 12px;
          width: 12px;
        }
      }
    }
    .Question-section:hover::after {
      cursor: pointer;
      // border-color: var(--hero-red, #ef2601);

      animation: bordercolorChange 1s ease; /* Use a CSS animation to change the color over 2 seconds */

      @include breakpoint-up(sm) {
        border-color: var(--hero-red, #ef2601);
        cursor: pointer;
        animation: none;
      }
    }
  }

  &.Question-dialog--true {
    .Question-section {
      &::after {
        content: "";
        right: 12px;

        position: absolute;
        border-right: 3px solid #000;
        border-top: 3px solid #000;
        display: inline-block;
        height: 10px;
        width: 10px;
        transform: rotate(135deg) translate(-16.7%, 16.7%);

        @include breakpoint-up(sm) {
          right: 30px;
          border-right: 4px solid #000;
          border-top: 4px solid #000;
          height: 12px;
          width: 12px;
        }
      }
    }
    .Question-section:hover::after {
      cursor: pointer;
      // border-color: var(--hero-red, #ef2601);

      animation: bordercolorChange 1s ease; /* Use a CSS animation to change the color over 2 seconds */

      @include breakpoint-up(sm) {
        border-color: var(--hero-red, #ef2601);
        cursor: pointer;
        animation: none;
      }
    }
  }
}

.Question-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--solid-black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-right: 20px;
  padding-right: 20px;

  @include breakpoint-up(sm) {
    line-height: 32px;
    font-size: 20px;
    width: unset;
  }
}

.Answer-section {
  color: var(--grey-dk-grey, #282828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 20px;

  ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    list-style: none;
    padding-inline: 0;
    margin-top: 0px;

    li {
      display: flex;
      gap: 4px;
      align-self: stretch;
      color: var(--solid-black, #000);
      font-size: 16px;
      font-style: normal;
      padding-bottom: 8px;
    }
  }

  p {
    font-size: 14px;
    margin: 0px;
    color: var(--grey-dk-grey, #282828);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  a {
    font-weight: 500;
    color: var(--grey-dk-grey, #282828);
    &:hover {
      text-decoration: underline;
      color: #ef2601;
    }
  }
}

.Faq-Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @include breakpoint-up(sm) {
    flex-direction: row;
  }

  p {
    color: var(--solid-black, #000);
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;

    @include breakpoint-up(sm) {
      font-size: 20px;
    }
  }
}
.Footer-Link {
  color: var(--solid-black, #000);
  font-size: 16px;
  font-family: Red Hat Text;
  font-weight: 500;
  line-height: 32px;

  &:hover {
    color: var(--hero-red, #ef2601);
    text-decoration: underline;
  }

  @include breakpoint-up(sm) {
    font-size: 20px;
  }
}

.downloadLinkWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  .downloadLink {
    display: flex;
    padding: 20px;
    border: 1px solid var(--grey-lt-grey-89, #898989);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;

    > div {
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }

    @include breakpoint-up(md) {
      flex-direction: column;
      width: unset;
      gap: 10px;
    }
  }
  @include breakpoint-up(md) {
    flex-direction: row;
  }
}

.installStepsWrapper {
  display: flex;
  flex-direction: column;
}

em {
  font-family: Red Hat Text;
  font-weight: 500;
  font-style: normal;
}

@keyframes bordercolorChange {
  0% {
    border-color: #ef2601;
  }
  100% {
    border-color: #ef2601;
  }
}

 /* Define the keyframes for the animation */
 @keyframes colorChange {
  0% {
    color: #ef2601;
  }
  100% {
    color: #ef2601;
  }
}