@import 'src/styles/functions';
@import 'src/styles/keyframes';
@import 'src/styles/mixins';
@import 'src/styles/variables';


.VideoTileArea {
	min-height: 200px;
	overflow: hidden;
	padding: 14px var(--body-margin);

	@include breakpoint-up(md) {
		min-height: 390px;
		padding: 28px var(--body-margin);
	}
}

.VideoTileAreaFavChannel {
	min-height: 200px;
	overflow: hidden;

	@include breakpoint-up(md) {
		min-height: 390px;
	}
}
