.Error {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	padding: 0 10vw;
	text-align: center;
	width: 100%;
}
.Error.custom-error{
	height: 60vh;
}

.Error-title {
	font-size: 40px;
	margin-bottom: 30px;
}

.Error-message {
	font-size: 24px;
	margin-bottom: 40px;
}
