@import 'src/styles/variables';
@import 'src/styles/mixins';

.AppPromo-wrapper {
	background: $body-background;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 100vw;
	overflow-y: auto;
	padding: 10%;

	@include breakpoint-up(sm) {
		height: auto;
		min-height: 100vh;
	}
}

.AppPromo-meta {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;

	.BrandBar {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: unset; // reset existing margins
		margin: 20px 0px;

		@include breakpoint-up(sm) {
			margin-top: 40px;
			width: 80%;
		}

		.BrandBar-link {
			animation: unset;
			margin: 0 0 25px 0;
			opacity: 0.5;
			width: 33%;
		}

		.BrandBar-link-image {
			height: 40px;
			width: 58px;

			@include breakpoint-up(sm) {
				height: 66px;
				width: 94px;
			}

			@include breakpoint-up(md) {
				height: 79px;
				width: 100px;
			}
		}

		.BrandBar-globalnewsott {
			height: 50px;
			width: 70px;

			@include breakpoint-up(sm) {
				height: 79px;
				width: 105px;
			}

			@include breakpoint-up(md) {
				height: 90px;
				width: 119px;
			}
		}

		.BrandBar-global,
		.BrandBar-wnetwork {
			height: 45px;
			width: 62px;

			@include breakpoint-up(sm) {
				height: 72px;
				width: 99px;
			}

			@include breakpoint-up(md) {
				height: 85px;
				width: 114px;
			}
		}

		.BrandBar-natgeo {
			height: 35px;
			width: 95px;

			@include breakpoint-up(sm) {
				height: 60px;
				width: 140px;
			}

			@include breakpoint-up(md) {
				width: 160px;
			}
		}

		.BrandBar-adultswim {
			height: 35px;
			width: 100px;

			@include breakpoint-up(sm) {
				height: 60px;
				width: 140px;
			}

			@include breakpoint-up(md) {
				width: 160px;
			}
		}
	}
}

.AppPromo-brand-logo {
	margin: 0 auto;
	width: 80px;

	@include breakpoint-up(sm) {
		width: 100px;
	}
}

.AppPromo-hero-title {
	font-family: $title-font;
	font-size: 18px;
	font-weight: $font-weight-light;
	margin: 30px 0px;
	text-align: center;

	@include breakpoint-up(sm) {
		font-size: 20px;
	}
}

.AppPromo-store-links-wrapper {
	text-align: center;
}

.Store-links-heading {
	font-family: $title-font;
	font-size: 14px;
	font-weight: $font-weight-extra-light;

	@include breakpoint-up(sm) {
		font-size: 22px;
	}
}

.Store-links-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 15px;

	@include breakpoint-up(sm) {
		margin-top: 50px;
	}

	.storeLink {
		height: 40px;

		@include breakpoint-up(sm) {
			height: 60px;
			margin-right: 15px;
			max-width: 200px;
		}
	}

	.storeLink--playStore {
		margin-left: 10px;
	}
}