@import 'src/styles/variables';

@mixin hide-text {
    text-indent: -99999px;
}

@mixin breakpoint-up($bp) {
    $bp-size: map-get($breakpoints, $bp);
    @if ($bp-size == 0) {
        @content;
    } @else {
        @media (min-width: $bp-size) {
            @content;
        }
    }
}

@mixin breakpoint($min-bp,$max-bp) {
    $min-bp-size: map-get($breakpoints, $min-bp);
    $max-bp-size: map-get($breakpoints, $max-bp);

    @if ($min-bp-size == 0) {
        @content;
    } @else {
        @media (min-width: $min-bp-size) and (max-width:  $max-bp-size) {
            @content;
        }
    }
}

// Applies a value to a CSS property for each breakpoint in a given map.
@mixin breakpoint-property($map, $property) {
    @each $bp, $value in $map {
        @include breakpoint-up($bp) {
            #{$property}: $value;
        }
    }
}

$arrowDirections: (
    right: 45deg,
    down: 135deg,
    left: 225deg,
    up: 315deg
);

// CSS arrow mixin (i.e. empty right triangle, or > character). Uses a square with a border.
@mixin cssArrow($direction: right, $line: 1px solid $body-font-color, $size: 30px, $absoluteCentered: false) {
	$posOffset: null;

	@if ($absoluteCentered) {
		$posOffset: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		top: 50%;
	}

	border-right: $line;
	border-top: $line;
	display: inline-block;
	height: $size;
	transform: $posOffset rotate(#{map-get($arrowDirections, $direction)}) translate(-16.7%, 16.7%);
	width: $size;
}

@mixin createLine($bg-color: $loader-bg-color, $height: 10px, $radius: 10px, $width: 100%) {
	background: $bg-color;
	height: $height;
	width: $width;
	border-radius: $radius;
}