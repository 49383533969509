@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/keyframes';

.Shows {
	.GridTileArea {
		padding-top: 15px;

		&.isLoading--false {
			animation: fade-in-and-slide-down 0.75s ease-in-out;
		}
	}

	.Shows-title {
		animation: $default-fade-in;
		display: block;
		font-family: $title-font;
		font-size: 20px;
		font-weight: $font-weight-medium;
		margin-bottom: 40px;
		text-align: center;

		@include breakpoint-up(md) {
			font-size: 32px;
		}
	}

	.Shows-brandBar {
		padding-bottom: 16px;
	}

}
