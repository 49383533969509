@import "src/styles/mixins";
@import "src/styles/variables";
@import "src/styles/keyframes";

$authmodal-font-color: #101010;

.AuthModal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  touch-action: none;
  width: 100%;
  z-index: 1000000;
}

.AuthModal {
  animation: zoom-in 0.4s ease forwards;
  background-color: #fefefe;
  border-radius: 5px;
  color: $authmodal-font-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 24px;

  @include breakpoint-up(sm) {
    height: 680px;
    width: 80%;
    max-width: 730px;
    padding: 32px;
  }
}

.AuthModal-close-btn {
  animation: zoom-out 0.2s ease-out forwards;
}

.AuthModal-body {
  display: flex;
  flex: 1 0 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 0;
  overflow: hidden;

}

.authStep-3 {
  .AuthModal-body {
    flex-direction: column;
    justify-content: space-between;
  }
}

.authStep-1 .AuthModal-back,
.authStep-3 .AuthModal-back {
  display: none;
}

.AuthModal-back {
  display: flex;
  align-items: center;
  left: -12px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  color: #282828;


  img {
    width: 40px;
  }
}

.AuthModal-close,
.AuthModal-back {
  padding:0px
}

.AuthModal-close {
  font-size: 2.1rem;
  color: #101010;
  position: relative;
  // top: -12px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #efefef;
  padding: 0px;
}

.AuthModal-header-top,
.AuthModal-header-bottom {
  display: flex;
  flex-flow: row-reverse;
  font-size: 16px;
  justify-content: space-between;
  text-transform: uppercase;
}

.AuthModal-header-top{
  margin-bottom: 16px;
}

.AuthModal-header-bottom {
  border-bottom: 1px #979797 solid;
  padding-bottom: 0.6rem;
}
.signin-title {
  font-weight: $font-weight-medium;
}

.bdu-providers {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin: 0px;

  li {
    background-color: #efefef;
    cursor: pointer;
    display: flex;
    margin: 1%;
    min-height: 60px;
    padding: 0% 3%;
    position: relative;
    width: 44%;

    @include breakpoint-up(sm) {
      margin: 0.5rem;
      min-height: 80px;
      width: 24%;
    }
  }

  .bdu-image {
    margin: auto;
  }

  img {
    border: none;
    font-size: 1vw;
    height: auto;
    margin: auto;
    max-width: 85%;

    @include breakpoint-up(sm) {
      max-width: 100%;
    }
  }
}

.bdu-picker {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  width: 100%;
}

.auth-modal-text {
  flex: 0 0 auto;
  overflow: auto;
  font-size: 14px;
  margin-top: 24px;
  color: #282828;

  @include breakpoint-up(sm) {
    font-size: 16px;
  }

  &--bolder {
    font-weight: $font-weight-medium;
    font-size: 16px;
    padding-bottom: 8px;
  }
  p {
    margin: 0;
    white-space: pre-wrap;
  }
}

.unauthorized-cta {
  margin: 0 auto;
  font-size: 12px;
}

.bdu-providers-container {
  border-bottom: 1px solid #ccc;
  flex: 1 1 auto;
  margin: 24px auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.auth-modal-footer {
  flex: 0 0 auto;

  p {
    margin-bottom: 0;
    margin-top: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 14px;
    color: #282828;

    @include breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  .banner-rokuActivate{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  a {
    color: $authmodal-font-color;

    &:hover {
      color: $globaltv-red;
      text-decoration: underline;
      transition: all $default-transition;
    }

    img {
      width: 100%;
      max-width: 300px;
    }
  }
  span {
    display: inline-block;
    margin-top: 2rem;
    a {
      text-decoration: underline;
    }
  }
}

.single-bdu {
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;

  .Spinner {
    font-size: 60px;
    height: 40%;
  }
}

.noscroll {
  overflow: hidden;
  position: fixed;
  width: 100vw;
}

.auth-final-step {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include breakpoint-up(sm) {
    padding-bottom: 20px;
  }

  .auth-selection > p {
    margin-bottom: 0;
    margin-top: 1rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 12px;
    color: #282828;

    @include breakpoint-up(sm) {
      font-size: 14px;
    }

    @include breakpoint-up(sm) {
      font-size: 16px;
    }
  }
}


.auth-authorized-resources {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.auth-authorized-resources ul,
.auth-unauthorized-resources ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
  list-style: none;
  margin-bottom: auto;
  margin-top: 2px;
  padding-left: 0;
  width: 100%;

  @include breakpoint-up(sm) {
    gap: 16px;
  }

  li.resource-logo {
    display: flex;
    margin: 0;
    height: auto;
    min-height: 45px;
    position: relative;
    width: 25%;
    max-width: 70px;

    @include breakpoint-up(sm) {
      width: 18%;
      max-width: 85px;
    }

    @include breakpoint-up(md) {
      height: 64px;
      max-width: 100px;
    }

    &--small {
      margin-bottom: auto;
      width: 25%;
    }

    @include breakpoint-up(sm) {
      padding: 5px;
    }

    img {
      border: none;
      height: auto;
      margin: auto;
      width: 70%;
    }

    &--global img {
      width: 90%;
    }

    &--magnolia img,
    &--lifetime img {
      width: 100%;
    }

    &--history img {
      width: 55%;
    }

    &--wnetwork img {
      width: 60%;
    }

    &--hgtv img {
      width: 75%;
    }

    &--foodnetwork img {
      width: 62%;
    }

    &--natgeoca img,
    &--adultswim img {
      width: 100%;
    }
  }
}

.AuthModal-ok-button {
  font-size: 14px;
  margin-inline: auto;
  padding: 9px 16px;

  &:hover {
    color: $globaltv-red;
  }

  @include breakpoint-up(md) {
    font-size: 16px;
    padding: 12px 20px;
  }
}
