@import "src/styles/mixins";

.Banner-image {
    height: auto;
    opacity: 1;
    width: 100%;
	padding-top: 12px ;

	@include breakpoint-up(md) {
		padding-top: 18px;
	}
}