@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/shared-styles';

html {
	box-sizing: border-box;

	@each $variable, $map in $css-variables {
		@include breakpoint-property($map, $variable);
	}
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	background: $body-background;
	color: $body-font-color;
	font-family: $body-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	overscroll-behavior: none;

	&:not(.isFullscreen) {
		overflow-y: scroll;
	}
}

a {
	color: $body-font-color;
	text-decoration: none;
	position: relative;
}
p {
	font-weight: $font-weight-normal;
}
// H1, H2... TBD

h3 {
	font-family: $title-font;
	font-size: 12px;
	font-weight: $font-weight-medium;

	@include breakpoint-up(md) {
		font-size: 14px;
	}

	@include breakpoint-up(lg) {
		font-size: 18px;
	}
}

button {
	background: transparent;
	border: none;
	color: $body-font-color;
	cursor: pointer;
	font: 16px $body-font-family;
	pointer-events: visible;
}

.button-primary {
	@extend %button-primary;
}

.button-secondary {
	@extend %button-secondary;
}