@keyframes tile-loading-animation {
	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% { 
		opacity: 0; 
	}
	100% { 
		opacity: 1; 
	}
}

@keyframes slow-fade-out {
	0% {
		opacity: 1;
		z-index: 100;
	}
	33% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		z-index: 0;
	}
}

@keyframes fade-in-with-opacity {
	0% { 
		opacity: 0;
	}
	100% { 
		opacity: 0.5;
	}
}

@keyframes fade-out-and-slide-up {
	0% { 
		opacity: 1;
		max-height: 1000px;
	}
	100% {
		opacity: 0;
		max-height: 0;
	}
}

@keyframes fade-in-and-slide-down {
	0% { 
		opacity: 0;
		max-height: 150px;
	}
	100% {
		opacity: 1;
		max-height: 2000px;
	}
}

@keyframes swipe-in {
	0% { 
		transform: translateX(3%);
		opacity: 0.7;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(0.7);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes zoom-out {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.6);
		opacity: 0;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes pulse {
	0% { 
		opacity: 1; 
	}
	50% {
		opacity: 0.3;
	}
	100% { 
		opacity: 1;
	}
}

@keyframes expand {
	0% {
		opacity: 0.5;
		width: 30px;
	}

	100% {
		opacity: 1;
		width: 100%;
	}
}

@keyframes smooth-slide-down {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}