@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/keyframes';


.OverlayModelWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  align-items: center;
  z-index: 1000000;
  touch-action: none;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.8);
  width: 100%;

  .OverlayModel {
    color: $body-font-color;
    overflow: hidden;
    padding: 32px 48px 32px 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    animation: zoom-in 0.4s ease forwards;
    background-color: #000;
    border-radius: 5px;
    max-width: 720px;
    width: 540px;
    justify-content: center;

    .heading{
      font-family: $title-font;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      color: $body-font-color;
      padding-bottom:16px;
    }
    .subHeading{
      font-family: $proxima-nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20.3px;
      text-align: center;
      margin: 0px;
      color: $body-font-color;
    }

    .subHeadingLink{
        text-decoration: underline;
    }
    .button-box{
      padding-top: 32px;

      .overlayBtn{
        background-color: $body-font-faded-color;
        width: 150px;
        border: 2px solid $body-font-faded-color;
        outline: none;
        margin: 12px;
        padding: 8px;
        &:hover {
          background-color: transparent ;
        }
      }

    }
    .spinner-box{
      position: absolute;
      width:100%;
      height:100%;
      left:0px;
      right:0px;
      top:0;
      background-color: #fefefe ;
      .Spinner{
        top:40%;
        font-size: 30px;
      }
    }

  }

  .ClosingModel{
    animation: zoom-out 0.2s ease-out forwards;
  }
}