@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/keyframes';

.Home > .BrandBar {
	text-align: center;
}

.Home {
	.HomeLoader {
		animation: ease-in 1.5s slow-fade-out;
		background-color: $body-background;
		height: 100vh;
		opacity: 0;
		position: fixed;
		width: 100vw;
	}
}