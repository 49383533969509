@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/shared-styles";

.Brand-wrapper {
  display: flex;
  min-height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  opacity: 0.699999988079071;
  gap: 16px 16px;
  flex-wrap: wrap;
  margin-top: 56px;

  @include breakpoint-up(sm) {
    gap: 16px 48px;
    margin-top: 48px;

  }

  @include breakpoint-up(md) {
    gap: 32px 48px;
    margin-top: 48px;
    width: 935px;   
    margin-inline: auto;
  }
  @include breakpoint-up(xl) {
    width: unset;
  }
}

.Brand-image {
  width: 91.8px;
  height: auto;
  object-fit: contain;

  // targetted for lesser than iOS v14
  @supports (-webkit-touch-callout: none)and (not (translate: none)) {
    &:not(:last-child) {
      margin-right: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.BrandBar-globalnewsott {
  width: 52px;

  @include breakpoint-up(mm) {
    width: 65px;
  }

  @include breakpoint-up(lg) {
    width: 77px;
  }
}

.BrandBar-wnetwork,
.BrandBar-food {
  width: 32px;

  @include breakpoint-up(mm) {
    width: 45px;
  }

  @include breakpoint-up(lg) {
    width: 46px;
  }
}
.BrandBar-history {
  width: 28px;

  @include breakpoint-up(mm) {
    width: 34px;
  }

  @include breakpoint-up(lg) {
    width: 34px;
  }
}
.BrandBar-global,
.BrandBar-lifetime,
.BrandBar-adultswim {
  width: 50px;

  @include breakpoint-up(mm) {
    width: 65px;
  }

  @include breakpoint-up(lg) {
    width: 75px;
  }
}

.BrandBar-natgeo,
.BrandBar-adultswim,
.BrandBar-magnolia {
  width: 70px;

  @include breakpoint-up(mm) {
    width: 75px;
  }

  @include breakpoint-up(lg) {
    width: 87px;
  }
}

.BrandBar-slice,
.BrandBar-showcase,
.BrandBar-hgtv {
  width: 48px;

  @include breakpoint-up(sm) {
    width: 54px;
  }

  @include breakpoint-up(mm) {
    width: 65px;
  }

  @include breakpoint-up(lg) {
    width: 64px;
  }
}
