@import "src/styles/mixins";

.devices-wrapper {
  display: flex;
  position: relative;
  margin-top: 8px;
  padding: 24px 0;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  row-gap: 8px;
  flex-wrap: wrap;
  border: 1px solid var(--grey-md-grey, #5e5e5e);

  @include breakpoint-up(sm) {
    padding: 32px 16px;
    row-gap: 16px;

  }
}

.device-heading {
  display: flex;
  position: absolute;
  top: -10px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--solid-background, #141414);
  color: var(--grey-lt-grey-89, #898989);
  text-align: center;
  font-size: 16px;

}

.device-info {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  color: var(--hero-white, #f2f2f2);
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;

  @include breakpoint-up(sm) {
    padding: 8px 12px;
  }

  @include breakpoint-up(xm) {
    font-size: 15px;
  }
}
