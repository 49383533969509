@import 'src/styles/keyframes';
@import 'src/styles/mixins';

.Hero {
	min-height: calc(100vw / 2);
	position: relative;

	.TileSlider {
		display: block;
		margin-bottom: -75px;

		@include breakpoint-up(md) {
			margin-bottom: -120px;
		}

		@include breakpoint-up(lg) {
			margin-bottom: -150px;
		}

		.Hero-image {
			height: auto;
			opacity: 1;
			width: 100%;
		}
	}

	.Hero-image-wrapper {
		mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 50%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 95%);

		&::before {
			content:" ";
			position: absolute;
			width: 40%;
			height: 100%;
			background-image: linear-gradient(to right, rgba(20,20,20,1) 0%, rgba(20,20,20,0) 100%);
			z-index: 99999;
		}
	}

	.TileSlider-navigate {
		display: none;
	}
}
