@import 'src/styles/mixins';

.Show-wrapper {
	.Close-show-section {
		display: none;
	}

	margin-top: -33px;

	@include breakpoint-up(md) {
		margin-top: -75px;
	}
}

.Show-experience-appear {
	opacity: 0;
	transform: scale(1.05);
}

.Show-experience-appear.Show-experience-appear-active {
	opacity: 1;
	transform: scale(1);
	transition: all 1s ease;
}

.Show > .BrandBar {
	padding: 35px 0 20px;
	text-align: center;

	@include breakpoint-up(md) {
		padding-top: 50px;
	}
}