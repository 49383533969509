@import 'src/styles/variables';
@import 'src/styles/mixins';

.TileSlider-dots-wrapper {
	align-items: flex-end;
	bottom: 25%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	list-style: none;
	padding: 0 var(--body-margin);
	pointer-events: none;
	position: absolute;
	width: 100%;
	z-index: 9;

	.Hero-content-wrapper.active {
		margin-right: auto;
		order: 1;
	}

	.TileSlider-dot {
		cursor: pointer;
		margin-right: 12px;
		order: 2;
		pointer-events: all;

		@include breakpoint-up(md) {
			margin-right: 16px;
			&:last-child {
				margin-right: 0;
			}
		}

		@include breakpoint-up(lg) {
			margin-right: 20px;
		}

		&:before {
			background: white;
			border-radius: 9px;
			content: '';
			display: inline-block;
			height: 7px;
			opacity: 0.4;
			transition: all 300ms ease-in-out;
			width: 7px;

			@include breakpoint-up(md) {
				height: 10px;
				width: 10px;
			}

			@include breakpoint-up(lg) {
				height: 15px;
				width: 15px;
			}
		}

		&.active {
			&:before {
				background: $body-font-color;
				opacity: 1;
			}
		}
	}
}
